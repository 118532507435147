import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logo from "../media/images/logo.png";
import logo_purple2 from '../media/images/logo_purple2.png';
import Houda from "../media/images/houda2.jpg"
import Maryem from "../media/images/maryem2.jpg"
import Nadia from "../media/images/nadia2.jpg"
import Blog1 from "../media/images/blog1.png";
import Blog2 from "../media/images/blog2.png";
import Blog3 from "../media/images/blog3.png";
import Blog4 from "../media/images/blog4.png";
import Blog5 from "../media/images/blog5.png";
import Blog6 from "../media/images/blog6.jpeg";
import LanguageIcon from '../icons/language';
import ikbal from "../media/images/ikbal.png"
function Article({ lang, setLang }) {

  const { articlePath } = useParams();

  const navigate = useNavigate();


  useEffect(() => {
    if (!articlePath || !(articles.map(article => article.path).includes(articlePath))) {
      navigate("/");
    }
  }, [])

  const articles = [
    {
      path: "studying-abroad-why-it-matters-more-than-ever",
      englishTitle: "Studying Abroad: Why It Matters More Than Ever ?",
      frenchTitle: "Étudier à l'étranger : Pourquoi cela importe-t-il plus que jamais ?",
      englishContent: [
        { type: "text", value: "Studying abroad is more than just a trend or a checkbox on a resume; it's a transformative experience that can shape the trajectory of one's life in profound ways. As the world becomes increasingly interconnected, the importance of studying abroad has only grown. In this article, we delve into why studying abroad matters now more than ever." },
        { type: "title", value: "Cultural Immersion and Global Perspective:" },
        { type: "num", value: "Studying abroad provides a unique opportunity to immerse oneself in a different culture, language, and way of life. It's a chance to step outside of one's comfort zone and gain a deeper understanding of the world. By living and learning in a foreign country, students develop a global perspective that is invaluable in today's interconnected world." },
        { type: "title", value: "Personal Growth and Independence:" },
        { type: "num", value: "Leaving behind familiar surroundings and venturing into the unknown fosters personal growth and independence. Studying abroad requires adaptability, resilience, and problem-solving skills as students navigate new environments and overcome challenges on their own. These experiences not only build character but also instill confidence and self-reliance that will benefit students throughout their lives." },
        { type: "title", value: "Academic Enrichment and Career Opportunities:" },
        { type: "num", value: "Studying abroad opens doors to academic opportunities that may not be available at home. Whether it's attending prestigious universities, gaining hands-on experience through internships, or conducting research in specialized fields, studying abroad can enhance one's academic credentials and broaden their skill set. Additionally, exposure to different cultures and perspectives can make individuals more attractive to employers in an increasingly globalized job market." },
        { type: "title", value: "Cross-Cultural Communication and Language Skills:" },
        { type: "num", value: "Effective communication across cultural boundaries is becoming essential in today's multicultural societies and globalized workplaces. Studying abroad provides a natural environment for honing cross-cultural communication skills and language proficiency. By interacting with locals and fellow international students, students learn to navigate cultural nuances, break down language barriers, and collaborate effectively in diverse settings." },
        { type: "title", value: "Lifelong Connections and Memories:" },
        { type: "num", value: "Studying abroad often creates lifelong connections and cherished memories. The friendships forged with classmates from around the world and the shared experiences of exploration and discovery create bonds that transcend borders. These connections not only enrich one's personal life but also create professional networks that can span the globe, opening doors to future opportunities and collaborations." },
        { type: "title", value: "Cultivation of Empathy and Open-Mindedness:" },
        { type: "num", value: "Exposure to different cultures, perspectives, and ways of life fosters empathy and open-mindedness. It encourages individuals to question their assumptions, challenge stereotypes, and see the world through the eyes of others. In an era marked by polarization and divisiveness, cultivating empathy and open-mindedness is more important than ever, and studying abroad can play a crucial role in this process." },
        { type: "text", value: "In conclusion, studying abroad is not just about academic credits or ticking off items on a bucket list; it's about personal growth, cultural immersion, and global citizenship. In an increasingly interconnected world, the ability to navigate cultural differences, communicate effectively across borders, and embrace diversity is essential. By studying abroad, students not only expand their horizons but also contribute to building a more interconnected, empathetic, and understanding world. So, if you ever have the chance to study abroad, seize it—it may just change your life in ways you never imagined." },
        { type: "text2", value: "" },
        { type: "image", src: Houda, alt: "Written by Houda Badre, Educational Consultant", name: " Written by Houda Badre", description: "Educational Consultant" }
      ],
      "frenchContent": [
        { type: "text", value: "Étudier à l'étranger est bien plus qu'une simple tendance ou une case à cocher sur un CV ; c'est une expérience transformative qui peut façonner la trajectoire de la vie de quelqu'un de manière profonde. Alors que le monde devient de plus en plus interconnecté, l'importance des études à l'étranger n'a fait que croître. Dans cet article, nous explorons pourquoi étudier à l'étranger compte désormais plus que jamais." },
        { type: "title", value: "Immersion culturelle et perspective mondiale :" },
        { type: "num", value: "Étudier à l'étranger offre une opportunité unique de s'immerger dans une culture, une langue et un mode de vie différents. C'est une chance de sortir de sa zone de confort et de mieux comprendre le monde. En vivant et en apprenant dans un pays étranger, les étudiants développent une perspective mondiale qui est inestimable dans le monde interconnecté d'aujourd'hui." },
        { type: "title", value: "Croissance personnelle et indépendance :" },
        { type: "num", value: "Quitter des environnements familiers et s'aventurer dans l'inconnu favorise la croissance personnelle et l'indépendance. Étudier à l'étranger exige de l'adaptabilité, de la résilience et des compétences en résolution de problèmes alors que les étudiants naviguent dans de nouveaux environnements et surmontent des défis par eux-mêmes. Ces expériences ne font pas seulement forger le caractère, mais elles insufflent également confiance en soi et autonomie, ce qui bénéficiera aux étudiants tout au long de leur vie." },
        { type: "title", value: "Enrichissement académique et opportunités professionnelles :" },
        { type: "num", value: "Étudier à l'étranger ouvre des portes à des opportunités académiques qui peuvent ne pas être disponibles chez soi. Que ce soit en fréquentant des universités prestigieuses, en acquérant de l'expérience pratique grâce à des stages, ou en menant des recherches dans des domaines spécialisés, étudier à l'étranger peut améliorer les références académiques et élargir les compétences. De plus, l'exposition à différentes cultures et perspectives peut rendre les individus plus attrayants pour les employeurs dans un marché du travail de plus en plus globalisé." },
        { type: "title", value: "Communication interculturelle et compétences linguistiques :" },
        { type: "num", value: "La communication efficace à travers les frontières culturelles devient essentielle dans les sociétés multiculturelles et les lieux de travail mondialisés d'aujourd'hui. Étudier à l'étranger offre un environnement naturel pour perfectionner les compétences en communication interculturelle et la maîtrise des langues. En interagissant avec les habitants et les autres étudiants internationaux, les étudiants apprennent à naviguer dans les subtilités culturelles, à briser les barrières linguistiques et à collaborer efficacement dans des contextes divers." },
        { type: "title", value: "Connexions et souvenirs durables :" },
        { type: "num", value: "Étudier à l'étranger crée souvent des connexions durables et des souvenirs précieux. Les amitiés forgées avec des camarades de classe du monde entier et les expériences partagées d'exploration et de découverte créent des liens qui transcendent les frontières. Ces connexions enrichissent non seulement la vie personnelle, mais elles créent également des réseaux professionnels qui peuvent s'étendre à travers le monde, ouvrant ainsi des portes à de futures opportunités et collaborations." },
        { type: "title", value: "Cultivation de l'empathie et de l'ouverture d'esprit :" },
        { type: "num", value: "L'exposition à différentes cultures, perspectives et modes de vie favorise l'empathie et l'ouverture d'esprit. Elle encourage les individus à remettre en question leurs préjugés, à défier les stéréotypes et à voir le monde à travers les yeux des autres. Dans une ère marquée par la polarisation et la division, cultiver l'empathie et l'ouverture d'esprit est plus important que jamais, et étudier à l'étranger peut jouer un rôle crucial dans ce processus." },
        { type: "text", value: "En conclusion, étudier à l'étranger ne se limite pas à des crédits académiques ou à cocher des éléments sur une liste de choses à faire ; il s'agit de croissance personnelle, d'immersion culturelle et de citoyenneté mondiale. Dans un monde de plus en plus interconnecté, la capacité à naviguer dans les différences culturelles, à communiquer efficacement à travers les frontières et à embrasser la diversité est essentielle. En étudiant à l'étranger, les étudiants élargissent non seulement leurs horizons, mais contribuent également à construire un monde plus interconnecté, empathique et compréhensif. Alors, si vous avez jamais l'occasion d'étudier à l'étranger, saisissez-la – cela pourrait bien changer votre vie de manière que vous n'ayez jamais imaginée." },
        { type: "text2", value: "" },
        { type: "image", src: Houda, alt: "Écrit par Houda Badre, Consultant en éducation", name: "Écrit par Houda Badre", "description": "Consultante en éducation" }
      ]
    },
    {
      path: "8-reasons-for-Moroccan-to-study-abroad-in-china",
      englishTitle: "8 Reasons for Moroccan to Study Abroad in China",
      frenchTitle: "8 Raisons pour les Marocains d'Étudier à l'Étranger en Chine",
      englishContent: [
        { type: "text", value: "For Moroccan students with dreams as vast as the Sahara, the prospect of studying abroad is like embarking on a journey across uncharted territories. While destinations in Europe and North America often steal the spotlight, there lies a hidden gem in the East waiting to be discovered—China. Far beyond the Atlas Mountains, China beckons with its allure of ancient traditions, modern marvels, and boundless opportunities. Here's a glimpse into why Moroccan students should consider venturing to the Far East for their academic pursuits." },
        { type: "title", value: "The Silk Road of Culture:" },
        { type: "num", value: "Just as caravans traversed the historic Silk Road, carrying goods and ideas between East and West, studying in China offers Moroccan students a gateway to a rich tapestry of culture and heritage. From the majestic Forbidden City to the tranquil gardens of Suzhou, China's cultural landscape is a treasure trove waiting to be explored. By immersing themselves in Chinese customs, language, and cuisine, Moroccan students can embark on a transformative journey of cultural exchange and discovery." },
        { type: "title", value: "Mandarin Magic:" },
        { type: "num", value: "In a world where connectivity is key, fluency in Mandarin Chinese opens doors to a universe of opportunities. As the most spoken language globally, mastering Mandarin not only enhances communication skills but also unlocks access to China's booming economy and vibrant job market. Whether pursuing business ventures or academic endeavors, Moroccan students stand to gain a competitive edge by adding Mandarin to their linguistic repertoire." },
        { type: "title", value: "Academic Adventure:" },
        { type: "num", value: "China's rise as a global powerhouse is mirrored in its academic institutions, which rank among the world's best. From prestigious universities in Beijing and Shanghai to emerging hubs in Hangzhou and Shenzhen, China offers a diverse array of academic programs tailored to meet the needs of modern learners. Moroccan students seeking excellence in fields ranging from engineering to medicine will find a fertile ground for intellectual growth and exploration in China's dynamic academic landscape." },
        { type: "title", value: "Innovation Oasis:" },
        { type: "num", value: "At the crossroads of tradition and innovation, China is a melting pot of creativity and entrepreneurship. From cutting-edge technology firms in Shenzhen to bustling startup scenes in Beijing, the country's vibrant ecosystem fosters innovation and ingenuity. Moroccan students with an entrepreneurial spirit will find inspiration and mentorship in China's thriving startup culture, where ideas are nurtured and dreams are turned into reality." },
        { type: "title", value: "East Meets West:" },
        { type: "num", value: "In a world where East meets West, studying in China offers Moroccan students a unique vantage point to bridge cultures and forge connections across continents. By engaging with Chinese students, international peers, and expatriate communities, Moroccan students can broaden their perspectives, challenge their assumptions, and cultivate friendships that transcend borders. In the global village of the 21st century, cultural fluency and cross-cultural competence are invaluable assets that transcend geographical boundaries." },
        { type: "title", value: "Wanderlust Wonders:" },
        { type: "num", value: "Beyond the confines of campus life, China beckons with its diverse landscapes and storied landmarks. From the picturesque karst mountains of Guilin to the vibrant streets of Hong Kong, every corner of China tells a story waiting to be discovered. Moroccan students studying in China have the opportunity to embark on epic adventures, whether hiking the Great Wall, exploring ancient temples, or savoring street food delights. Each journey is a chapter in the epic saga of exploration and self-discovery." },
        { type: "title", value: "Personal Growth:" },
        { type: "num", value: "Studying abroad is not just about acquiring knowledge—it's also about embarking on a journey of personal growth and self-discovery. By stepping out of their comfort zones and embracing new experiences, Moroccan students studying in China can cultivate resilience, independence, and adaptability. Whether navigating the bustling streets of Shanghai or mastering the art of chopstick etiquette, every challenge is an opportunity for growth and transformation." },
        { type: "title", value: "Global Citizenship:" },
        { type: "num", value: "In an increasingly interconnected world, global citizenship is more important than ever. By studying abroad in China, Moroccan students can gain firsthand insights into one of the world's oldest civilizations and its role in shaping the global landscape. Whether aspiring to become diplomats, entrepreneurs, or cultural ambassadors, Moroccan students studying in China are poised to become global citizens equipped with the knowledge, skills, and perspectives to navigate an ever-changing world." },
        { type: "text", value: "For Moroccan students with a spirit of adventure and a thirst for knowledge, studying abroad in China is a voyage of discovery waiting to unfold. Beyond the Atlas Mountains lies a land of opportunity and wonder, where ancient traditions meet modern innovation, and East meets West. By embracing the mystique of studying abroad in China, Moroccan students can embark on a transformative journey that transcends borders and enriches their lives in ways they never imagined possible." },
        { type: "text2", value: "" },
        { type: "image", src: Maryem, alt: "Meryem Derni, CO-FOUNDER OF DIRAVENIR", name: "Written by Meryem Derni", description: "CO-FOUNDER OF DIRAVENIR" }
      ],
      frenchContent: [
        { type: "text", value: "Pour les étudiants marocains aux rêves aussi vastes que le Sahara, la perspective d'étudier à l'étranger ressemble à un voyage à travers des territoires inexplorés. Alors que les destinations en Europe et en Amérique du Nord volent souvent la vedette, il existe un joyau caché à l'Est qui attend d'être découvert : la Chine. Bien au-delà des montagnes de l'Atlas, la Chine attire avec son allure de traditions anciennes, de merveilles modernes et d'opportunités illimitées. Voici un aperçu de pourquoi les étudiants marocains devraient envisager de se rendre en Extrême-Orient pour leurs études." },
        { type: "title", value: "La Route de la Soie de la Culture :" },
        { type: "num", value: "Tout comme les caravanes traversaient l'historique Route de la Soie, transportant des biens et des idées entre l'Est et l'Ouest, étudier en Chine offre aux étudiants marocains une porte d'entrée vers une riche mosaïque de culture et de patrimoine. De la majestueuse Cité interdite aux jardins tranquilles de Suzhou, le paysage culturel de la Chine est un trésor qui attend d'être exploré. En s'immergeant dans les coutumes, la langue et la cuisine chinoises, les étudiants marocains peuvent se lancer dans un voyage transformateur d'échange culturel et de découverte." },
        { type: "title", value: "La Magie du Mandarin :" },
        { type: "num", value: "Dans un monde où la connectivité est essentielle, la maîtrise du mandarin chinois ouvre les portes à un univers d'opportunités. En tant que langue la plus parlée au monde, la maîtrise du mandarin améliore non seulement les compétences en communication, mais ouvre également l'accès à l'économie florissante et au marché du travail dynamique de la Chine. Que ce soit pour entreprendre des ventures commerciales ou des projets académiques, les étudiants marocains ont tout à gagner en ajoutant le mandarin à leur répertoire linguistique." },
        { type: "title", value: "Aventure Académique :" },
        { type: "num", value: "L'ascension de la Chine en tant que puissance mondiale se reflète dans ses institutions académiques, qui figurent parmi les meilleures du monde. Des universités prestigieuses de Pékin et Shanghai aux pôles émergents de Hangzhou et Shenzhen, la Chine propose une diversité de programmes académiques adaptés aux besoins des apprenants modernes. Les étudiants marocains en quête d'excellence dans des domaines allant de l'ingénierie à la médecine trouveront un terreau fertile pour la croissance intellectuelle et l'exploration dans le paysage académique dynamique de la Chine." },
        { type: "title", value: "Oasis de l'Innovation :" },
        { type: "num", value: "Au carrefour de la tradition et de l'innovation, la Chine est un creuset de créativité et d'entrepreneuriat. Des firmes technologiques de pointe à Shenzhen aux scènes de start-up animées de Pékin, l'écosystème dynamique du pays favorise l'innovation et l'ingéniosité. Les étudiants marocains dotés d'un esprit d'entreprise trouveront inspiration et mentorat dans la culture florissante des start-ups en Chine, où les idées sont nourries et les rêves deviennent réalité." },
        { type: "title", value: "Quand l'Orient Rencontre l'Occident :" },
        { type: "num", value: "Dans un monde où l'Orient rencontre l'Occident, étudier en Chine offre aux étudiants marocains un point de vue unique pour combler les cultures et forger des liens à travers les continents. En dialoguant avec des étudiants chinois, des pairs internationaux et des communautés d'expatriés, les étudiants marocains peuvent élargir leurs horizons, remettre en question leurs hypothèses et cultiver des amitiés qui transcendent les frontières. Dans le village mondial du 21e siècle, la fluidité culturelle et la compétence interculturelle sont des atouts inestimables qui transcendent les frontières géographiques." },
        { type: "title", value: "Merveilles du Voyage :" },
        { type: "num", value: "Au-delà de la vie universitaire, la Chine attire par ses paysages variés et ses sites légendaires. Des montagnes karstiques pittoresques de Guilin aux rues animées de Hong Kong, chaque coin de la Chine raconte une histoire qui ne demande qu'à être découverte. Les étudiants marocains étudiant en Chine ont l'opportunité de se lancer dans des aventures épiques, que ce soit en parcourant la Grande Muraille, en explorant des temples anciens ou en savourant les délices de la cuisine de rue. Chaque voyage est un chapitre de la saga épique de l'exploration et de la découverte de soi." },
        { type: "title", value: "Croissance Personnelle :" },
        { type: "num", value: "Étudier à l'étranger ne consiste pas seulement à acquérir des connaissances, mais aussi à entreprendre un voyage de croissance personnelle et de découverte de soi. En sortant de leur zone de confort et en embrassant de nouvelles expériences, les étudiants marocains étudiant en Chine peuvent cultiver la résilience, l'indépendance et l'adaptabilité. Que ce soit en naviguant dans les rues animées de Shanghai ou en maîtrisant l'art de l'étiquette des baguettes, chaque défi est une opportunité de croissance et de transformation." },
        { type: "title", value: "Citoyenneté Globale :" },
        { type: "num", value: "Dans un monde de plus en plus interconnecté, la citoyenneté mondiale est plus importante que jamais. En étudiant à l'étranger en Chine, les étudiants marocains peuvent acquérir des insights de première main dans l'une des civilisations les plus anciennes du monde et son rôle dans la formation du paysage mondial. Que ce soit en aspirant à devenir diplomates, entrepreneurs ou ambassadeurs culturels, les étudiants marocains étudiant en Chine sont prêts à devenir des citoyens du monde équipés des connaissances, des compétences et des perspectives nécessaires pour naviguer dans un monde en constante évolution." },
        { type: "text", value: "Pour les étudiants marocains avec un esprit d'aventure et une soif de connaissance, étudier à l'étranger en Chine est un voyage de découverte qui attend d'être dévoilé. Au-delà des montagnes de l'Atlas se trouve une terre d'opportunité et d'émerveillement, où les traditions anciennes rencontrent l'innovation moderne, et l'Orient rencontre l'Occident. En embrassant la mystique d'étudier à l'étranger en Chine, les étudiants marocains peuvent entreprendre un voyage transformateur qui transcende les frontières et enrichit leur vie de manière qu'ils n'ont jamais imaginée possible." },
        { type: "text2", value: "" },
        { type: "image", "src": Maryem, alt: "Meryem Derni, CO-FONDATRICE DE DIRAVENIR", name: "Écrit par Meryem Derni", description: "CO-FONDATRICE DE DIRAVENIR" }
      ]
    },
    {
      path: "8-reasons-to-study-in-turkish-cyprus-as-a-moroccan-student",
      englishTitle: "8 Reasons to Study in Turkish Cyprus as a Moroccan Student",
      frenchTitle: "8 Raisons d'Étudier à Chypre Turque en tant qu'Étudiant Marocain",
      englishContent: [
        { type: "text", value: "In the realm of international education, there are destinations that shine brightly on the global stage, drawing students from far and wide. Yet, amidst the well-known educational hubs, there exists a hidden gem waiting to be discovered: Turkish Cyprus. Tucked away in the eastern Mediterranean, this lesser-explored destination offers a unique blend of academic excellence, cultural richness, and unparalleled experiences. Here are some compelling reasons why studying in Turkish Cyprus could be the transformative journey you've been seeking:" },
        { type: "title", value: "Strategic Location and Cultural Crossroads:" },
        { type: "num", value: "Nestled at the crossroads of Europe, Asia, and Africa, Turkish Cyprus boasts a strategic location that has shaped its rich tapestry of cultures and civilizations. Studying here offers unparalleled opportunities for cultural immersion and exploration. From ancient ruins to vibrant markets, students can delve into a melting pot of influences, including Greek, Turkish, and Middle Eastern traditions." },
        { type: "title", value: "Internationally Recognized Universities:" },
        { type: "num", value: "Despite its small size, Turkish Cyprus is home to several internationally recognized universities offering a diverse range of academic programs. Whether you're interested in engineering, business, humanities, or the sciences, you'll find reputable institutions with modern facilities and experienced faculty members dedicated to fostering academic excellence." },
        { type: "title", value: "Affordable Tuition and Living Costs:" },
        { type: "num", value: "One of the most appealing aspects of studying in Turkish Cyprus is its affordability. Compared to many other study destinations, tuition fees and living costs are significantly lower, making it an attractive option for budget-conscious students. With affordable accommodation, dining, and transportation options, students can enjoy a high quality of life without breaking the bank." },
        { type: "title", value: "Multicultural Community and Global Networking:" },
        { type: "num", value: "Turkish Cyprus welcomes students from diverse backgrounds, creating a multicultural community that enriches the learning experience. By studying alongside peers from around the world, students gain valuable insights into different cultures, languages, and perspectives. This multicultural environment also fosters global networking opportunities that can open doors to future collaborations and career prospects." },
        { type: "title", value: "Natural Beauty and Outdoor Adventures:" },
        { type: "num", value: "Beyond the classroom, Turkish Cyprus offers breathtaking natural beauty and outdoor adventures waiting to be explored. From pristine beaches and rugged coastlines to lush mountains and picturesque villages, the island is a paradise for nature lovers and outdoor enthusiasts. Students can unwind after classes by hiking, swimming, or simply soaking in the stunning scenery." },
        { type: "title", value: "Warm Hospitality and Safe Environment:" },
        { type: "num", value: "Turkish Cypriots are renowned for their warm hospitality and friendly demeanor, creating a welcoming environment for international students. With its low crime rate and laid-back atmosphere, Turkish Cyprus is considered one of the safest study destinations in the Mediterranean region. Students can focus on their studies and personal growth with peace of mind." },
        { type: "title", value: "Gateway to Cultural Exchange and Career Opportunities:" },
        { type: "num", value: "Studying in Turkish Cyprus opens doors to a world of cultural exchange and career opportunities. Whether through internships, volunteer opportunities, or cultural events, students can engage with local communities and gain valuable insights into the region's history, politics, and economy. Moreover, Turkish Cyprus serves as a gateway to neighboring countries, offering opportunities for travel, language immersion, and professional development." },
        { type: "title", value: "Rich History and Cultural Heritage:" },
        { type: "num", value: "Turkish Cyprus boasts a rich history spanning thousands of years, with influences from ancient civilizations such as the Greeks, Romans, Byzantines, and Ottomans. Studying here offers a unique opportunity to explore historical sites, archaeological treasures, and centuries-old monuments that bear witness to the island's storied past. From medieval castles and ancient ruins to charming old towns and traditional villages, students can immerse themselves in the island's cultural heritage, gaining a deeper appreciation for its diverse history and identity. By studying in Turkish Cyprus, students not only enrich their academic experience but also embark on a journey through time, unraveling the layers of history that have shaped this fascinating destination." },
        { type: "text", value: "In conclusion, Turkish Cyprus may be a hidden gem in the world of international education, but its allure is undeniable. With its strategic location, reputable universities, affordable costs, multicultural community, and natural beauty, studying here promises a transformative experience that combines academic excellence with cultural immersion and personal growth. So, dare to embark on this unique journey—it may lead you to discover a world of possibilities you never imagined." },
        { type: "text2", value: "" },
        { type: "image", src: Nadia, alt: "Nadia Boukdir, CO-FOUNDER OF DIRAVENIR", name: "Written by Nadia Boukdir", description: "CO-FOUNDER OF DIRAVENIR" }
      ],
      frenchContent: [
        { type: "text", value: "Dans le domaine de l'éducation internationale, il existe des destinations qui brillent sur la scène mondiale, attirant des étudiants de loin. Pourtant, parmi les pôles éducatifs bien connus, se trouve un joyau caché qui attend d'être découvert : Chypre turque. Nichée dans l'est de la Méditerranée, cette destination moins explorée offre un mélange unique d'excellence académique, de richesse culturelle et d'expériences inégalées. Voici quelques raisons convaincantes pour lesquelles étudier à Chypre turque pourrait être le voyage transformateur que vous recherchez :" },
        { type: "title", value: "Emplacement Stratégique et Carrefour Culturel :" },
        { type: "num", value: "Nichée au carrefour de l'Europe, de l'Asie et de l'Afrique, la Chypre turque bénéficie d'un emplacement stratégique qui a façonné sa riche mosaïque de cultures et de civilisations. Étudier ici offre des opportunités inégalées d'immersion culturelle et d'exploration. Des ruines antiques aux marchés animés, les étudiants peuvent plonger dans un creuset d'influences, incluant des traditions grecques, turques et moyen-orientales." },
        { type: "title", value: "Universités Reconnues Internationalement :" },
        { type: "num", value: "Malgré sa petite taille, la Chypre turque abrite plusieurs universités reconnues internationalement proposant une gamme diversifiée de programmes académiques. Que vous soyez intéressé par l'ingénierie, les affaires, les sciences humaines ou les sciences, vous trouverez des institutions réputées dotées d'installations modernes et de professeurs expérimentés dédiés à l'excellence académique." },
        { type: "title", value: "Frais de Scolarité et Coûts de Vie Abordables :" },
        { type: "num", value: "L'un des aspects les plus attrayants de l'étude en Chypre turque est son accessibilité financière. Comparés à de nombreuses autres destinations d'études, les frais de scolarité et les coûts de vie sont considérablement moins élevés, ce qui en fait une option attrayante pour les étudiants soucieux de leur budget. Avec des options d'hébergement, de restauration et de transport abordables, les étudiants peuvent profiter d'une qualité de vie élevée sans se ruiner." },
        { type: "title", value: "Communauté Multiculturelle et Réseautage Global :" },
        { type: "num", value: "La Chypre turque accueille des étudiants de divers horizons, créant une communauté multiculturelle qui enrichit l'expérience d'apprentissage. En étudiant aux côtés de pairs du monde entier, les étudiants acquièrent des perspectives précieuses sur différentes cultures, langues et perspectives. Cet environnement multiculturel favorise également des opportunités de réseautage global qui peuvent ouvrir des portes à des collaborations futures et à des perspectives de carrière." },
        { type: "title", value: "Beauté Naturelle et Aventures en Plein Air :" },
        { type: "num", value: "Au-delà de la salle de classe, la Chypre turque offre une beauté naturelle à couper le souffle et des aventures en plein air qui n'attendent qu'à être explorées. Des plages immaculées et des côtes escarpées aux montagnes verdoyantes et aux villages pittoresques, l'île est un paradis pour les amoureux de la nature et les passionnés de plein air. Après les cours, les étudiants peuvent se détendre en randonnant, en nageant ou simplement en profitant du paysage époustouflant." },
        { type: "title", value: "Hospitalité Chaleureuse et Environnement Sûr :" },
        { type: "num", value: "Les Chypriotes turcs sont réputés pour leur hospitalité chaleureuse et leur caractère amical, créant un environnement accueillant pour les étudiants internationaux. Avec son faible taux de criminalité et son atmosphère détendue, la Chypre turque est considérée comme l'une des destinations d'études les plus sûres de la région méditerranéenne. Les étudiants peuvent se concentrer sur leurs études et leur croissance personnelle en toute tranquillité d'esprit." },
        { type: "title", value: "Passerelle vers l'Échange Culturel et les Opportunités de Carrière :" },
        { type: "num", value: "Étudier en Chypre turque ouvre les portes à un monde d'échange culturel et d'opportunités de carrière. Que ce soit par le biais de stages, de volontariats ou d'événements culturels, les étudiants peuvent s'engager avec les communautés locales et acquérir des perspectives précieuses sur l'histoire, la politique et l'économie de la région. De plus, la Chypre turque sert de passerelle vers les pays voisins, offrant des opportunités de voyage, d'immersion linguistique et de développement professionnel." },
        { type: "title", value: "Histoire Riche et Patrimoine Culturel :" },
        { type: "num", value: "La Chypre turque possède une riche histoire remontant à des milliers d'années, avec des influences des anciennes civilisations telles que les Grecs, les Romains, les Byzantins et les Ottomans. Étudier ici offre une opportunité unique d'explorer des sites historiques, des trésors archéologiques et des monuments vieux de plusieurs siècles qui témoignent du passé légendaire de l'île. Des châteaux médiévaux et des ruines antiques aux charmantes vieilles villes et aux villages traditionnels, les étudiants peuvent se plonger dans le patrimoine culturel de l'île, développant ainsi une appréciation plus profonde pour son histoire et son identité diverses. En étudiant en Chypre turque, les étudiants enrichissent non seulement leur expérience académique, mais se lancent également dans un voyage à travers le temps, dévoilant les couches d'histoire qui ont façonné cette destination fascinante." },
        { type: "text", value: "En conclusion, la Chypre turque peut être un joyau caché dans le monde de l'éducation internationale, mais son attrait est indéniable. Avec son emplacement stratégique, ses universités réputées, ses coûts abordables, sa communauté multiculturelle et sa beauté naturelle, étudier ici promet une expérience transformante qui allie excellence académique, immersion culturelle et croissance personnelle. Alors, osez vous embarquer dans ce voyage unique - il pourrait vous conduire à découvrir un monde de possibilités que vous n'aviez jamais imaginées." },
        { type: "text2", value: "" },
        { type: "image", src: Nadia, alt: "Nadia Boukdir, CO-FONDATRICE DE DIRAVENIR", name: "Écrit par Nadia Boukdir", description: "CO-FONDATRICE DE DIRAVENIR" }
      ]
    },
    {
      path: "Experience-World-Class-Education-in-China",
      englishTitle: "Experience World-Class Education in China",
      frenchTitle: "Expérimentez une éducation de classe mondiale en Chine",
      englishContent: [
        { type: "text", value: "Choosing the right university for your international studies is a pivotal decision that can significantly influence your academic and professional future. China offers prestigious universities known for their high academic standards, diverse programs, and enriching cultural experiences. In this article, we delve into some of the most renowned universities in China, highlighting their specialties and advantages" },
        { type: "title", value: "Universities in China: Centers of Academic Excellence" },
        { type: "num1", value: "Tsinghua University" },
        { type: "num2", value: "Specialties: Engineering, Technology, Business" },
        { type: "num", value: "Tsinghua University, located in Beijing, is consistently ranked as one of the top universities in China and the world. Known for its rigorous academic standards, Tsinghua excels particularly in engineering, technology, and business. The university boasts state-of-the-art research facilities and a vibrant campus life, making it a top choice for ambitious students." },
        { type: "num1", value: "Peking University" },
        { type: "num2", value: "Specialties: Natural Sciences, Humanities, Social Sciences" },
        { type: "num", value: "Also situated in Beijing, Peking University is celebrated for its excellence in the natural sciences, humanities, and social sciences. It has a rich history and a strong emphasis on research and innovation. Peking University offers a diverse range of programs and has a reputation for producing influential leaders and scholars." },
        { type: "num1", value: "Fudan University" },
        { type: "num2", value: "Specialties: Medicine, Business, Arts" },
        { type: "num", value: "Located in Shanghai, Fudan University is another top-tier institution known for its outstanding programs in medicine, business, and the arts. With a global perspective and a commitment to academic excellence, Fudan attracts students from around the world, providing a rich cultural and academic environment." },
        { type: "title", value: "Advantages of Studying in China" },
        { type: "num1", value: "High Global Rankings" },
        { type: "num", value: "Chinese universities consistently rank among the top in the world, reflecting rigorous academic standards and cutting-edge research." },
        { type: "num1", value: "Diverse Programs" },
        { type: "num", value: "A wide range of programs across multiple disciplines ensures that students can find courses that align with their interests and career goals." },
        { type: "num1", value: "Affordable Education" },
        { type: "num", value: "Compared to Western countries, China offers more affordable education without compromising quality." },
        { type: "num1", value: "Advanced Research Facilities" },
        { type: "num", value: "Students have access to state-of-the-art laboratories and resources, allowing them to engage in groundbreaking research." },
        { type: "num1", value: "Cultural Immersion" },
        { type: "num", value: "Students can immerse themselves in China’s rich cultural heritage, learn Mandarin, and explore historical landmarks" },
        { type: "text", value: "In conclusion, studying in China offers students a chance to gain a world-class education in a dynamic and culturally rich environment. The combination of high academic standards, affordable education, and unique cultural experiences makes China an attractive destination for international students." },
        { type: "text", value: "At Diravenir, we are dedicated to helping you navigate the process of selecting the best university that matches your academic and personal aspirations. Start your journey with us and unlock a world of possibilities in China" },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Marketing Intern at Diravenir", name: "Written by Ikbal Mountassir", description: "Marketing Intern at Diravenir" }
      ],
      frenchContent: [
        { type: "text", value: "Choisir la bonne université pour vos études internationales est une décision cruciale qui peut influencer considérablement votre avenir académique et professionnel. La Chine propose des universités prestigieuses réputées pour leurs normes académiques élevées, leurs programmes diversifiés et leurs expériences culturelles enrichissantes. Dans cet article, nous explorons quelques-unes des universités les plus renommées en Chine, mettant en lumière leurs spécialités et leurs avantages." },
        { type: "title", value: "Universités en Chine : Centres d'Excellence Académique" },
        { type: "num1", value: "Université Tsinghua" },
        { type: "num2", value: "Spécialités : Ingénierie, Technologie, Affaires" },
        { type: "num", value: "L'Université Tsinghua, située à Beijing, est régulièrement classée parmi les meilleures universités en Chine et dans le monde. Réputée pour ses normes académiques rigoureuses, Tsinghua excelle particulièrement dans les domaines de l'ingénierie, de la technologie et des affaires. L'université dispose d'installations de recherche à la pointe de la technologie et d'une vie étudiante dynamique, en faisant un choix de premier plan pour les étudiants ambitieux" },
        { type: "num1", value: "Université de Pékin" },
        { type: "num2", value: "Spécialités : Sciences Naturelles, Sciences Humaines, Sciences Sociales" },
        { type: "num", value: "Également située à Beijing, l'Université de Pékin est renommée pour son excellence dans les sciences naturelles, les sciences humaines et les sciences sociales. Elle possède une riche histoire et met fortement l'accent sur la recherche et l'innovation. L'Université de Pékin propose une large gamme de programmes et jouit d'une réputation pour former des leaders et des chercheurs influents." },
        { type: "num1", value: "Université Fudan" },
        { type: "num2", value: "Spécialités : Médecine, Affaires, Arts" },
        { type: "num", value: "Située à Shanghai, l'Université Fudan est une autre institution de premier plan réputée pour ses programmes exceptionnels en médecine, en affaires et dans les arts. Avec une perspective mondiale et un engagement envers l'excellence académique, Fudan attire des étudiants du monde entier, offrant un environnement culturel et académique riche." },
        { type: "title", value: "Avantages d'étudier en Chine:" },
        { type: "num1", value: "Classements mondiaux élevés" },
        { type: "num", value: "Les universités chinoises figurent régulièrement parmi les meilleures au monde, reflétant des normes académiques rigoureuses et des recherches de pointe." },
        { type: "num1", value: "Programmes diversifiés." },
        { type: "num", value: "Une large gamme de programmes dans plusieurs disciplines permet aux étudiants de trouver des cours qui correspondent à leurs intérêts et à leurs objectifs de carrière." },
        { type: "num1", value: "Éducation abordable" },
        { type: "num", value: "Comparé aux pays occidentaux, la Chine offre une éducation plus abordable sans compromettre la qualité." },
        { type: "num1", value: "Installations de recherche avancées." },
        { type: "num", value: "Les étudiants ont accès à des laboratoires et à des ressources de pointe, ce qui leur permet de mener des recherches novatrices." },
        { type: "num1", value: "Immersion culturelle" },
        { type: "num", value: "Les étudiants peuvent s'immerger dans le riche patrimoine culturel de la Chine, apprendre le mandarin et explorer des sites historiques." },
        { type: "text", value: "En conclusion, étudier en Chine offre aux étudiants l'opportunité d'acquérir une éducation de classe mondiale dans un environnement dynamique et culturellement riche. La combinaison de normes académiques élevées, d'une éducation abordable et d'expériences culturelles uniques fait de la Chine une destination attrayante pour les étudiants internationaux." },
        { type: "text", value: "Chez Diravenir, nous nous engageons à vous aider à naviguer dans le processus de sélection de la meilleure université correspondant à vos aspirations académiques et personnelles. Commencez votre parcours avec nous et ouvrez un monde de possibilités en Chine." },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Stagiaire en marketing chez Diravenir", name: "Écrit par Ikbal Mountassir", description: "Stagiaire en marketing chez Diravenir" }
      ]
    },
    {
      path: "Discover-Top-Universities-in-Northern-Cyprus",
      englishTitle: "Discover Top Universities in Northern Cyprus",
      frenchTitle: "Découvrez les meilleures universités dans le nord de Chypre",
      englishContent: [
        { type: "text", value: "Cyprus, an island nation in the Mediterranean, has become a popular destination for international students seeking quality education. The country offers a diverse range of academic programs and boasts several prestigious universities. In this article, we explore four prominent universities in Cyprus: Final International University, Cyprus International University and Near East University." },
        { type: "title", value: "Final International University (FIU) " },
        { type: "num", value: "Final International University, located in Kyrenia, is known for its modern campus and diverse academic offerings. FIU provides a range of undergraduate and postgraduate programs in fields such as engineering, business, tourism, and education. The university emphasizes a student-centered approach, with a focus on practical learning and research opportunities." },
        { type: "num", value: "One of the unique aspects of FIU is its international community, with students from various countries coming together to study and collaborate. This multicultural environment enriches the student experience, fostering cross-cultural understanding and global networking opportunities. The university also offers various scholarships and financial aid options to support international students." },
        { type: "title", value: "Cyprus International University (CIU) " },
        { type: "num", value: "Cyprus International University, located in North Nicosia, is renowned for its commitment to academic excellence and innovation. CIU offers a wide range of programs, including engineering, arts, sciences, law, and health sciences. The university is equipped with state-of-the-art facilities and promotes research and development across various disciplines." },
        { type: "num", value: "CIU is also known for its vibrant campus life, offering numerous extracurricular activities and student organizations. The university places a strong emphasis on community engagement and social responsibility, encouraging students to participate in community service and sustainability initiatives. This holistic approach to education aims to develop well-rounded individuals prepared for global challenges." },
        { type: "title", value: "Near East University (NEU)" },
        { type: "num", value: "Near East University, also located in North Nicosia, is one of the largest and most established universities in Cyprus. NEU offers a comprehensive range of academic programs across various faculties, including medicine, engineering, economics, and humanities. The university is distinguished by its extensive research output and strong emphasis on scientific and technological advancements" },
        { type: "num", value: "NEU’s campus features modern facilities, including advanced laboratories, libraries, and recreational amenities. The university is committed to fostering an environment of innovation and intellectual growth, providing students with opportunities to engage in cutting-edge research and academic projects. NEU also offers a range of scholarships and financial support to attract talented students from around the world." },
        { type: "title", value: "Advantages of Studying in Northern Cyprus" },
        { type: "num1", value: "Strategic Location" },
        { type: "num", value: "Cyprus’s prime location at the crossroads of Europe, Asia, and Africa makes it an ideal destination for students looking to explore different parts of the world" },
        { type: "num1", value: "High-Quality Education" },
        { type: "num", value: "Universities in Cyprus are known for their high academic standards and global recognition." },
        { type: "num1", value: "English-Taught Programs" },
        { type: "num", value: "The widespread availability of English-taught programs ensures that international students can pursue their education without language barriers" },
        { type: "num1", value: "Vibrant Student Life" },
        { type: "num", value: "With beautiful beaches, a Mediterranean climate, and a lively cultural scene, Cyprus offers an exciting and enriching student life" },
        { type: "num1", value: "Safe and Welcoming Environment" },
        { type: "num", value: "Cyprus offers a low crime rate and friendly locals, creating a safe and welcoming environment for international students." },
        { type: "text", value: "In conclusion, Cyprus offers a diverse range of educational opportunities through its reputable universities. Whether you are seeking a multicultural campus environment, a strong focus on research, or an international education experience, the universities in Cyprus provide a solid foundation for academic and personal growth." },
        { type: "text", value: "At Diravenir, we are dedicated to helping you navigate the process of selecting the best university that matches your academic and personal aspirations. Start your journey with us and unlock a world of possibilities in Cyprus." },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Marketing Intern at Diravenir", name: "Written by Ikbal Mountassir", description: "Marketing Intern at Diravenir" }
      ],
      frenchContent: [
        { type: "text", value: "Chypre, une nation insulaire en Méditerranée, est devenue une destination populaire pour les étudiants internationaux en quête d'une éducation de qualité. Le pays offre une gamme diversifiée de programmes académiques et abrite plusieurs universités prestigieuses. Dans cet article, nous explorons quatre universités de premier plan à Chypre : Final International University, Cyprus International University et Near East University" },
        { type: "title", value: "Université Internationale Final (FIU):" },
        { type: "num", value: "L'Université Internationale Final, située à Kyrenia, est réputée pour son campus moderne et ses offres académiques diversifiées. FIU propose une gamme de programmes de premier et deuxième cycles dans des domaines tels que l'ingénierie, les affaires, le tourisme et l'éducation. L'université met l'accent sur une approche centrée sur l'étudiant, en privilégiant l'apprentissage pratique et les opportunités de recherche." },
        { type: "num", value: "L'un des aspects uniques de l'Université Internationale Final est sa communauté internationale, où des étudiants de différents pays se réunissent pour étudier et collaborer. Cet environnement multiculturel enrichit l'expérience des étudiants, favorisant la compréhension interculturelle et les opportunités de réseautage mondial. L'université offre également diverses bourses et options d'aide financière pour soutenir les étudiants internationaux." },
        { type: "title", value: "Université Internationale de Chypre (CIU): " },
        { type: "num", value: "L'Université Internationale de Chypre, située à Nicosie Nord, est renommée pour son engagement envers l'excellence académique et l'innovation. CIU offre un large éventail de programmes, notamment en ingénierie, arts, sciences, droit et sciences de la santé. L'université dispose d'installations à la pointe de la technologie et promeut la recherche et le développement dans diverses disciplines." },
        { type: "num", value: "CIU est également connue pour sa vie de campus dynamique, offrant de nombreuses activités parascolaires et organisations étudiantes. L'université met un fort accent sur l'engagement communautaire et la responsabilité sociale, encourageant les étudiants à participer à des services communautaires et à des initiatives de durabilité. Cette approche holistique de l'éducation vise à développer des individus bien équilibrés, préparés à relever les défis mondiaux." },
        { type: "title", value: "Université du Proche-Orient (NEU) :" },
        { type: "num", value: "L'Université du Proche-Orient, également située à Nicosie Nord, est l'une des plus grandes et des plus établies de Chypre. NEU offre une gamme complète de programmes académiques dans diverses facultés, y compris la médecine, l'ingénierie, l'économie et les sciences humaines. L'université se distingue par sa production de recherche extensive et son fort accent sur les avancées scientifiques et technologiques" },
        { type: "num", value: "Le campus de l'Université du Proche-Orient dispose d'installations modernes, comprenant des laboratoires avancés, des bibliothèques et des équipements récréatifs. L'université s'engage à promouvoir un environnement d'innovation et de croissance intellectuelle, offrant aux étudiants des opportunités de participer à des recherches de pointe et à des projets académiques. NEU propose également diverses bourses et soutiens financiers pour attirer des étudiants talentueux du monde entier" },
        { type: "title", value: "Avantages d'étudier dans le nord de Chypre:" },
        { type: "num1", value: "Emplacement stratégique:" },
        { type: "num", value: "La position privilégiée de Chypre au carrefour de l'Europe, de l'Asie et de l'Afrique en fait une destination idéale pour les étudiants désireux d'explorer différentes parties du monde" },
        { type: "num1", value: "Éducation de haute qualité:" },
        { type: "num", value: "Les universités à Chypre sont réputées pour leurs normes académiques élevées et leur reconnaissance mondiale" },
        { type: "num1", value: "Programmes enseignés en anglais:" },
        { type: "num", value: "La disponibilité généralisée de programmes enseignés en anglais permet aux étudiants internationaux de poursuivre leurs études sans rencontrer de barrières linguistiques" },
        { type: "num1", value: "Vie étudiante dynamique." },
        { type: "num", value: "Avec de magnifiques plages, un climat méditerranéen et une scène culturelle animée, Chypre offre une vie étudiante excitante et enrichissante." },
        { type: "num1", value: "Environnement sûr et accueillant." },
        { type: "num", value: "Chypre offre un faible taux de criminalité et des habitants sympathiques, créant un environnement sûr et accueillant pour les étudiants internationaux." },
        { type: "text", value: "En conclusion, Chypre offre une gamme diversifiée d'opportunités éducatives à travers ses universités réputées. Que vous recherchiez un environnement de campus multiculturel, un fort accent sur la recherche ou une expérience éducative internationale, les universités à Chypre fournissent une base solide pour la croissance académique et personnelle" },
        { type: "text", value: "Chez Diravenir, nous nous engageons à vous aider à naviguer dans le processus de sélection de la meilleure université qui correspond à vos aspirations académiques et personnelles. Commencez votre parcours avec nous et ouvrez la porte à un monde de possibilités à Chypre." },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Stagiaire en marketing chez Diravenir", name: "Écrit par Ikbal Mountassir", description: "Stagiaire en marketing chez Diravenir" }
      ]
    },
    {
      path: "Learning-Chinese-as-a-student-is-that-possible",
      englishTitle: "Learning Chinese as a student, is that possible?",
      frenchTitle: "Apprendre le chinois en tant qu'étudiant, est-ce possible?",
      englishContent: [
        { type: "text", value: "In today’s globalized world, learning a new language can open doors to a multitude of opportunities. Among the many languages available, Chinese stands out as one of the most beneficial to learn. With over a billion speakers, a rapidly growing economy, and rich cultural heritage, learning Chinese can provide you with a significant competitive edge. Here’s why learning Chinese is important and the opportunities it can unlock for you." },
        { type: "title", value: "The Importance of Learning Chinese" },
        { type: "num1", value: "Widespread Use:" },
        { type: "num", value: "Chinese is the most spoken language in the world, with over 1.3 billion native speakers. By learning Chinese, you can communicate with a vast number of people, not only in China but also in Chinese-speaking communities around the globe. This linguistic ability can enhance your personal and professional interactions significantly." },
        { type: "num1", value: "Economic Powerhouse:" },
        { type: "num", value: "China is the second-largest economy in the world and a major player in global trade and investment. Understanding Chinese can give you an edge in the business world, enabling you to navigate the Chinese market more effectively, build stronger business relationships, and access numerous economic opportunities." },
        { type: "num1", value: "Cultural Enrichment:" },
        { type: "num", value: "China has a rich cultural heritage that spans thousands of years. Learning Chinese opens a window into this ancient civilization, allowing you to appreciate its literature, philosophy, art, and traditions more deeply. This cultural knowledge can enrich your personal life and enhance your understanding of global diversity" },
        { type: "num1", value: "Academic and Research Opportunities:" },
        { type: "num", value: "Chinese universities are among the top-ranked institutions globally, offering high-quality education and cutting-edge research facilities. Learning Chinese can provide you with access to these academic opportunities, enabling you to study in China and benefit from its advanced educational system." },
        { type: "num1", value: "Career Advancement:" },
        { type: "num", value: "Proficiency in Chinese is a valuable asset in the job market. Many multinational companies seek employees who can speak Chinese and understand Chinese culture to manage their operations in China and engage with Chinese clients and partners. Whether you’re in business, technology, finance, or tourism, knowing Chinese can significantly boost your career prospects" },
        { type: "num1", value: "Travel and Exploration:" },
        { type: "num", value: "China is a vast and diverse country with stunning landscapes, historical landmarks, and vibrant cities. Knowing Chinese can enhance your travel experiences, allowing you to navigate the country more easily, communicate with locals, and gain a deeper appreciation of its unique attractions" },
        { type: "title", value: "Opportunities Unlocked by Learning Chinese" },
        { type: "num1", value: "Business and Trade:" },
        { type: "num", value: "China’s influence in global business is immense. Companies that operate in China or do business with Chinese partners highly value employees who can speak Chinese. Your ability to communicate in Chinese can lead to roles in international trade, business development, and global marketing. It can also give you a competitive edge in negotiations, enabling you to build stronger and more effective business relationships." },
        { type: "num1", value: "Education and Research:" },
        { type: "num", value: "Learning Chinese can open doors to numerous educational opportunities. Many Chinese universities offer scholarships to international students, providing an affordable way to pursue higher education. Moreover, China is a leader in various research fields, including technology, medicine, and environmental science. Proficiency in Chinese can give you access to collaborative research projects and academic exchanges." },
        { type: "num1", value: "Government and Diplomacy:" },
        { type: "num", value: "As China plays an increasingly important role in global affairs, there is a growing demand for diplomats, translators, and international relations experts who can speak Chinese. Careers in government, international organizations, and non-profits can be significantly enhanced by your ability to understand and communicate in Chinese, facilitating cross-cultural dialogue and cooperation." },
        { type: "num1", value: "Tourism and Hospitality:" },
        { type: "num", value: "China’s rich history and diverse culture attract millions of tourists each year. Proficiency in Chinese can lead to opportunities in the tourism and hospitality industry, including roles as tour guides, travel consultants, and hotel managers. Understanding the language and culture can help you provide better services and enhance the experiences of travelers" },
        { type: "num1", value: "Media and Translation:" },
        { type: "num", value: "The demand for Chinese language skills in media, journalism, and translation services is growing. As a Chinese speaker, you can pursue careers in international journalism, translating and interpreting, or content creation for Chinese-speaking audiences. Your ability to bridge the language gap can make you a valuable asset in the media industry" },
        { type: "num1", value: "Technology and Innovation:" },
        { type: "num", value: "China is a global leader in technology and innovation, with significant advancements in fields like artificial intelligence, telecommunications, and e-commerce. Knowledge of Chinese can provide opportunities to work with leading tech companies, participate in innovative projects, and stay updated with the latest technological trends emerging from China " },
        { type: "title", value: "How to Learn Chinese: Two Effective Paths" },
        { type: "num1", value: "Self-Study and Online Resources" },
        { type: "num", value: "For those who prefer a flexible learning schedule or are looking to get started at their own pace, self-study and online resources are excellent options:" },
        { type: "num2", value: "Online Courses and Apps:" },
        { type: "num", value: "Platforms like Duolingo, Rosetta Stone, and Coursera offer structured Chinese language courses that you can take at your own pace. These courses often include interactive lessons, quizzes, and progress tracking to help you stay motivated and on track" },
        { type: "num2", value: "Language Exchange:" },
        { type: "num", value: "Engaging in language exchange with native Chinese speakers can provide practical experience. Websites like Tandem and HelloTalk connect you with Chinese speakers who want to learn English or another language you know." },
        { type: "num2", value: "Multimedia Learning:" },
        { type: "num", value: "Utilize podcasts, YouTube channels, and Chinese TV shows and movies to enhance your listening and comprehension skills. This approach helps you immerse yourself in the language and culture even if you’re not in a Chinese-speaking environment" },
        { type: "num2", value: "Textbooks and Workbooks:" },
        { type: "num", value: "For a more traditional approach, textbooks like 'Integrated Chinese' and workbooks can provide a structured learning path. These resources often come with CDs or online access to audio files for listening practice" },
        { type: "num1", value: "Academic Programs" },
        { type: "num", value: "For those seeking a more immersive and formal education experience, enrolling in an academic program can be highly beneficial:" },
        { type: "num2", value: "University Courses:" },
        { type: "num", value: "Many universities offer Chinese language courses as part of their curriculum. Enrolling in these courses provides a structured learning environment, access to experienced instructors, and opportunities to interact with fellow students" },
        { type: "num2", value: "Language Institutes:" },
        { type: "num", value: "Dedicated language institutes in China, such as Beijing Language and Culture University or the Mandarin Training Center in Taipei, offer intensive language programs. These programs often include cultural immersion activities, making them ideal for those who wish to study abroad and experience Chinese culture firsthand" },
        { type: "num2", value: "Study Abroad Programs: " },
        { type: "num", value: "Enrolling in a study abroad program allows you to live and study in China. This immersive experience helps you practice the language daily, understand cultural nuances, and build a network of international and local peers" },
        { type: "num2", value: "Summer Schools and Intensive Courses:" },
        { type: "num", value: "For those with limited time, summer schools and intensive courses provide accelerated learning. These programs are typically shorter in duration but offer an immersive and focused curriculum" },
        { type: "text", value: "Learning Chinese is not just about mastering a language, it’s about unlocking a world of opportunities. From enhancing your career prospects and gaining access to top-tier educational institutions to deepening your cultural understanding and enriching your travel experiences, the benefits of learning Chinese are vast and varied" },
        { type: "text", value: "At Diravenir, we are dedicated to helping you embark on this rewarding journey. Whether you’re a student, professional, or enthusiast, we provide the resources and support you need to learn Chinese effectively and prepare for your study abroad experience. Our services include academic orientation, application assistance, visa support, pre-departure preparation, and post-arrival follow-up" },
        { type: "text", value: "Choosing to study abroad opens the door to a future filled with exciting possibilities and global connections. With Diravenir by your side, you can confidently navigate the process and find solutions to any challenges you might encounter along the way. Let us help you achieve your academic and career goals. Start your journey with Diravenir today and explore the incredible opportunities that await you" },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Marketing Intern at Diravenir", name: "Written by Ikbal Mountassir", description: "Marketing Intern at Diravenir" }
      ],
      frenchContent: [
        { type: "text", value: "Dans le monde globalisé d'aujourd'hui, apprendre une nouvelle langue peut ouvrir les portes à de nombreuses opportunités. Parmi les nombreuses langues disponibles, le chinois se distingue comme l'une des plus bénéfiques à apprendre. Avec plus d'un milliard de locuteurs, une économie en croissance rapide et un riche patrimoine culturel, apprendre le chinois peut vous fournir un avantage compétitif significatif. Voici pourquoi apprendre le chinois est important et les opportunités qu'il peut débloquer pour vous" },
        { type: "title", value: "L'importance d'apprendre le chinois" },
        { type: "num1", value: "Utilisation répandue :" },
        { type: "num", value: "Le chinois est la langue la plus parlée au monde, avec plus de 1,3 milliard de locuteurs natifs. En apprenant le chinois, vous pouvez communiquer avec un nombre impressionnant de personnes, non seulement en Chine mais aussi dans les communautés de langue chinoise à travers le monde. Cette capacité linguistique peut considérablement enrichir vos interactions personnelles et professionnelles" },
        { type: "num1", value: "Puissance économique :" },
        { type: "num", value: "La Chine est la deuxième plus grande économie mondiale et un acteur majeur du commerce et de l'investissement mondiaux. Comprendre le chinois peut vous donner un avantage dans le monde des affaires, vous permettant de naviguer plus efficacement sur le marché chinois, de bâtir des relations d'affaires plus solides et d'accéder à de nombreuses opportunités économiques" },
        { type: "num1", value: "Enrichissement culturel :" },
        { type: "num", value: "La Chine possède un riche patrimoine culturel qui s'étend sur des milliers d'années. Apprendre le chinois ouvre une fenêtre sur cette civilisation ancienne, vous permettant d'apprécier plus profondément sa littérature, sa philosophie, son art et ses traditions. Cette connaissance culturelle peut enrichir votre vie personnelle et améliorer votre compréhension de la diversité mondiale" },
        { type: "num1", value: "Opportunités académiques et de recherche :" },
        { type: "num", value: "Les universités chinoises figurent parmi les institutions les mieux classées à l'échelle mondiale, offrant une éducation de haute qualité et des installations de recherche de pointe. Apprendre le chinois peut vous ouvrir l'accès à ces opportunités académiques, vous permettant d'étudier en Chine et de bénéficier de son système éducatif avancé" },
        { type: "num1", value: "Avancement professionnel :" },
        { type: "num", value: "La maîtrise du chinois est un atout précieux sur le marché du travail. De nombreuses entreprises multinationales recherchent des employés capables de parler chinois et de comprendre la culture chinoise pour gérer leurs opérations en Chine et collaborer avec des clients et des partenaires chinois. Que vous travailliez dans les affaires, la technologie, la finance ou le tourisme, connaître le chinois peut considérablement augmenter vos perspectives de carrière" },
        { type: "num1", value: "Voyages et exploration :" },
        { type: "num", value: "La Chine est un pays vaste et diversifié avec des paysages magnifiques, des sites historiques remarquables et des villes dynamiques. Connaître le chinois peut enrichir vos expériences de voyage, vous permettant de naviguer plus facilement à travers le pays, de communiquer avec les habitants et d'apprécier davantage ses attractions uniques" },
        { type: "title", value: "Les opportunités débloquées par l'apprentissage du chinois" },
        { type: "num1", value: "Affaires et Commerce :" },
        { type: "num", value: "L'influence de la Chine dans les affaires mondiales est immense. Les entreprises qui opèrent en Chine ou font affaire avec des partenaires chinois apprécient grandement les employés capables de parler chinois. Votre capacité à communiquer en chinois peut vous ouvrir des opportunités dans le commerce international, le développement commercial et le marketing mondial. Cela peut également vous donner un avantage concurrentiel dans les négociations, vous permettant de construire des relations d'affaires plus solides et efficaces" },
        { type: "num1", value: "Éducation et Recherche :" },
        { type: "num", value: "Apprendre le chinois peut ouvrir les portes à de nombreuses opportunités éducatives. De nombreuses universités chinoises offrent des bourses aux étudiants internationaux, offrant ainsi une manière abordable de poursuivre des études supérieures. De plus, la Chine est un leader dans divers domaines de recherche, notamment la technologie, la médecine et les sciences environnementales. La maîtrise du chinois peut vous donner accès à des projets de recherche collaboratifs et à des échanges académiques" },
        { type: "num1", value: "Gouvernement et Diplomatie :" },
        { type: "num", value: "Alors que la Chine joue un rôle de plus en plus important dans les affaires mondiales, il y a une demande croissante pour des diplomates, des traducteurs et des experts en relations internationales capables de parler chinois. Les carrières dans le gouvernement, les organisations internationales et les organisations à but non lucratif peuvent bénéficier considérablement de votre capacité à comprendre et à communiquer en chinois, facilitant le dialogue et la coopération interculturels" },
        { type: "num1", value: "Tourisme et Hospitalité :" },
        { type: "num", value: "L'histoire riche et la culture diversifiée de la Chine attirent des millions de touristes chaque année. La maîtrise du chinois peut ouvrir des opportunités dans l'industrie du tourisme et de l'hospitalité, notamment en tant que guide touristique, consultant en voyages et gestionnaire d'hôtel. Comprendre la langue et la culture peut vous aider à fournir de meilleurs services et à améliorer l'expérience des voyageurs" },
        { type: "num1", value: "Média et Traduction :" },
        { type: "num", value: "La demande pour les compétences en langue chinoise dans les médias, le journalisme et les services de traduction est en croissance. En tant que locuteur chinois, vous pouvez envisager des carrières dans le journalisme international, la traduction et l'interprétation, ou la création de contenu pour les audiences chinoises. Votre capacité à combler le fossé linguistique peut faire de vous un atout précieux dans l'industrie des médias" },
        { type: "num1", value: "Technologie et Innovation :" },
        { type: "num", value: "La Chine est un leader mondial dans la technologie et l'innovation, avec des avancées significatives dans des domaines tels que l'intelligence artificielle, les télécommunications et le commerce électronique. La connaissance du chinois peut offrir des opportunités de travailler avec des entreprises technologiques de premier plan, de participer à des projets innovants et de rester à jour avec les dernières tendances technologiques émergentes de Chine" },
        { type: "title", value: "Comment apprendre le chinois : Deux chemins efficaces" },
        { type: "num1", value: "Auto-apprentissage et ressources en ligne" },
        { type: "num", value: "Pour ceux qui préfèrent un emploi du temps flexible ou qui souhaitent commencer à leur propre rythme, l'auto-apprentissage et les ressources en ligne sont d'excellentes options :" },
        { type: "num2", value: "Cours en ligne et applications :" },
        { type: "num", value: "Des plateformes comme Duolingo, Rosetta Stone et Coursera proposent des cours structurés de langue chinoise que vous pouvez suivre à votre rythme. Ces cours comprennent souvent des leçons interactives, des quiz et un suivi de progression pour vous aider à rester motivé et sur la bonne voie" },
        { type: "num2", value: "Échange linguistique :" },
        { type: "num", value: "Participer à un échange linguistique avec des locuteurs natifs chinois peut vous offrir une expérience pratique. Des sites web comme Tandem et HelloTalk vous mettent en relation avec des locuteurs chinois qui souhaitent apprendre l'anglais ou une autre langue que vous connaissez." },
        { type: "num2", value: "Apprentissage multimédia :" },
        { type: "num", value: "Utilisez des podcasts, des chaînes YouTube, ainsi que des émissions de télévision et des films chinois pour améliorer vos compétences d'écoute et de compréhension. Cette approche vous aide à vous immerger dans la langue et la culture même si vous n'êtes pas dans un environnement de langue chinoise" },
        { type: "num2", value: "Manuels et cahiers d'exercices :" },
        { type: "num", value: "Pour une approche plus traditionnelle, les manuels comme 'Integrated Chinese' et les cahiers d'exercices peuvent fournir un chemin d'apprentissage structuré. Ces ressources sont souvent accompagnées de CDs ou d'un accès en ligne à des fichiers audio pour la pratique de l'écoute" },
        { type: "num1", value: "Programmes académiques" },
        { type: "num", value: "Pour ceux qui recherchent une expérience d'apprentissage plus immersive et formelle, s'inscrire à un programme académique peut être très bénéfique :" },
        { type: "num2", value: "Cours universitaires :" },
        { type: "num", value: "De nombreuses universités proposent des cours de langue chinoise dans leur programme. S'inscrire à ces cours offre un environnement d'apprentissage structuré, l'accès à des instructeurs expérimentés et des occasions d'interagir avec d'autres étudiants" },
        { type: "num2", value: "Instituts de langues :" },
        { type: "num", value: "Les instituts de langues dédiés en Chine, tels que l'Université de langue et de culture de Beijing ou le Centre de formation en mandarin à Taipei, proposent des programmes intensifs de langue. Ces programmes incluent souvent des activités d'immersion culturelle, ce qui les rend idéaux pour ceux qui souhaitent étudier à l'étranger et vivre la culture chinoise de première main" },
        { type: "num2", value: "Programmes d'études à l'étranger : " },
        { type: "num", value: "S'inscrire à un programme d'études à l'étranger vous permet de vivre et d'étudier en Chine. Cette expérience immersive vous aide à pratiquer la langue quotidiennement, à comprendre les subtilités culturelles et à construire un réseau de pairs internationaux et locaux" },
        { type: "num2", value: "Écoles d'été et cours intensifs :" },
        { type: "num", value: "Pour ceux qui ont peu de temps, les écoles d'été et les cours intensifs offrent un apprentissage accéléré. Ces programmes sont généralement de courte durée mais offrent un curriculum immersif et concentré." },
        { type: "text", value: "Apprendre le chinois ne se limite pas à maîtriser une langue ; c'est aussi débloquer un monde d'opportunités. Que ce soit pour améliorer vos perspectives de carrière, accéder à des institutions éducatives de premier plan, approfondir votre compréhension culturelle ou enrichir vos expériences de voyage, les avantages de l'apprentissage du chinois sont vastes et variés" },
        { type: "text", value: "Chez Diravenir, nous sommes dédiés à vous aider à entreprendre ce voyage enrichissant. Que vous soyez étudiant, professionnel ou passionné, nous vous fournissons les ressources et le soutien nécessaires pour apprendre le chinois efficacement et vous préparer à votre expérience d'études à l'étranger. Nos services comprennent l'orientation académique, l'aide à la candidature, le soutien visa, la préparation avant le départ et le suivi après l'arrivée" },
        { type: "text", value: "Choisir d'étudier à l'étranger ouvre la porte à un avenir rempli de possibilités passionnantes et de connections mondiales. Avec Diravenir à vos côtés, vous pouvez naviguer avec confiance dans le processus et trouver des solutions à tout défi que vous pourriez rencontrer en cours de route. Laissez-nous vous aider à atteindre vos objectifs académiques et professionnels. Commencez votre voyage avec Diravenir dès aujourd'hui et explorez les incroyables opportunités qui vous attendent" },
        { type: "text2", value: "" },
        { type: "image", src: ikbal, alt: "Ikbal Mountassir, Stagiaire en marketing chez Diravenir", name: "Écrit par Ikbal Mountassir", description: "Stagiaire en marketing chez Diravenir" }
      ]
    },
    {
      path: "test",
      englishTitle: "test",
      frenchTitle: "test",
      englishContent: [
        { type: "text", value: "In the realm of international education, there are destinations that shine brightly on the global stage, drawing students from far and wide. Yet, amidst the well-known educational hubs, there exists a hidden gem waiting to be discovered: Turkish Cyprus. Tucked away in the eastern Mediterranean, this lesser-explored destination offers a unique blend of academic excellence, cultural richness, and unparalleled experiences. Here are some compelling reasons why studying in Turkish Cyprus could be the transformative journey you've been seeking:" },
        { type: "title", value: "Strategic Location and Cultural Crossroads:" },
        { type: "num", value: "Nestled at the crossroads of Europe, Asia, and Africa, Turkish Cyprus boasts a strategic location that has shaped its rich tapestry of cultures and civilizations. Studying here offers unparalleled opportunities for cultural immersion and exploration. From ancient ruins to vibrant markets, students can delve into a melting pot of influences, including Greek, Turkish, and Middle Eastern traditions." },
        { type: "title", value: "Internationally Recognized Universities:" },
        { type: "num", value: "Despite its small size, Turkish Cyprus is home to several internationally recognized universities offering a diverse range of academic programs. Whether you're interested in engineering, business, humanities, or the sciences, you'll find reputable institutions with modern facilities and experienced faculty members dedicated to fostering academic excellence." },
        { type: "title", value: "Affordable Tuition and Living Costs:" },
        { type: "num", value: "One of the most appealing aspects of studying in Turkish Cyprus is its affordability. Compared to many other study destinations, tuition fees and living costs are significantly lower, making it an attractive option for budget-conscious students. With affordable accommodation, dining, and transportation options, students can enjoy a high quality of life without breaking the bank." },
        { type: "title", value: "Multicultural Community and Global Networking:" },
        { type: "num", value: "Turkish Cyprus welcomes students from diverse backgrounds, creating a multicultural community that enriches the learning experience. By studying alongside peers from around the world, students gain valuable insights into different cultures, languages, and perspectives. This multicultural environment also fosters global networking opportunities that can open doors to future collaborations and career prospects." },
        { type: "title", value: "Natural Beauty and Outdoor Adventures:" },
        { type: "num", value: "Beyond the classroom, Turkish Cyprus offers breathtaking natural beauty and outdoor adventures waiting to be explored. From pristine beaches and rugged coastlines to lush mountains and picturesque villages, the island is a paradise for nature lovers and outdoor enthusiasts. Students can unwind after classes by hiking, swimming, or simply soaking in the stunning scenery." },
        { type: "title", value: "Warm Hospitality and Safe Environment:" },
        { type: "num", value: "Turkish Cypriots are renowned for their warm hospitality and friendly demeanor, creating a welcoming environment for international students. With its low crime rate and laid-back atmosphere, Turkish Cyprus is considered one of the safest study destinations in the Mediterranean region. Students can focus on their studies and personal growth with peace of mind." },
        { type: "title", value: "Gateway to Cultural Exchange and Career Opportunities:" },
        { type: "num", value: "Studying in Turkish Cyprus opens doors to a world of cultural exchange and career opportunities. Whether through internships, volunteer opportunities, or cultural events, students can engage with local communities and gain valuable insights into the region's history, politics, and economy. Moreover, Turkish Cyprus serves as a gateway to neighboring countries, offering opportunities for travel, language immersion, and professional development." },
        { type: "title", value: "Rich History and Cultural Heritage:" },
        { type: "num", value: "Turkish Cyprus boasts a rich history spanning thousands of years, with influences from ancient civilizations such as the Greeks, Romans, Byzantines, and Ottomans. Studying here offers a unique opportunity to explore historical sites, archaeological treasures, and centuries-old monuments that bear witness to the island's storied past. From medieval castles and ancient ruins to charming old towns and traditional villages, students can immerse themselves in the island's cultural heritage, gaining a deeper appreciation for its diverse history and identity. By studying in Turkish Cyprus, students not only enrich their academic experience but also embark on a journey through time, unraveling the layers of history that have shaped this fascinating destination." },
        { type: "text", value: "In conclusion, Turkish Cyprus may be a hidden gem in the world of international education, but its allure is undeniable. With its strategic location, reputable universities, affordable costs, multicultural community, and natural beauty, studying here promises a transformative experience that combines academic excellence with cultural immersion and personal growth. So, dare to embark on this unique journey—it may lead you to discover a world of possibilities you never imagined." },
        { type: "text2", value: "" },
        { type: "image", src: Nadia, alt: "Nadia Boukdir, CO-FOUNDER OF DIRAVENIR", name: "Written by Nadia Boukdir", description: "CO-FOUNDER OF DIRAVENIR" }
      ],
      frenchContent: [
        { type: "text", value: "Dans le domaine de l'éducation internationale, il existe des destinations qui brillent sur la scène mondiale, attirant des étudiants de loin. Pourtant, parmi les pôles éducatifs bien connus, se trouve un joyau caché qui attend d'être découvert : Chypre turque. Nichée dans l'est de la Méditerranée, cette destination moins explorée offre un mélange unique d'excellence académique, de richesse culturelle et d'expériences inégalées. Voici quelques raisons convaincantes pour lesquelles étudier à Chypre turque pourrait être le voyage transformateur que vous recherchez :" },
        { type: "title", value: "Emplacement Stratégique et Carrefour Culturel :" },
        { type: "num", value: "Nichée au carrefour de l'Europe, de l'Asie et de l'Afrique, la Chypre turque bénéficie d'un emplacement stratégique qui a façonné sa riche mosaïque de cultures et de civilisations. Étudier ici offre des opportunités inégalées d'immersion culturelle et d'exploration. Des ruines antiques aux marchés animés, les étudiants peuvent plonger dans un creuset d'influences, incluant des traditions grecques, turques et moyen-orientales." },
        { type: "title", value: "Universités Reconnues Internationalement :" },
        { type: "num", value: "Malgré sa petite taille, la Chypre turque abrite plusieurs universités reconnues internationalement proposant une gamme diversifiée de programmes académiques. Que vous soyez intéressé par l'ingénierie, les affaires, les sciences humaines ou les sciences, vous trouverez des institutions réputées dotées d'installations modernes et de professeurs expérimentés dédiés à l'excellence académique." },
        { type: "title", value: "Frais de Scolarité et Coûts de Vie Abordables :" },
        { type: "num", value: "L'un des aspects les plus attrayants de l'étude en Chypre turque est son accessibilité financière. Comparés à de nombreuses autres destinations d'études, les frais de scolarité et les coûts de vie sont considérablement moins élevés, ce qui en fait une option attrayante pour les étudiants soucieux de leur budget. Avec des options d'hébergement, de restauration et de transport abordables, les étudiants peuvent profiter d'une qualité de vie élevée sans se ruiner." },
        { type: "title", value: "Communauté Multiculturelle et Réseautage Global :" },
        { type: "num", value: "La Chypre turque accueille des étudiants de divers horizons, créant une communauté multiculturelle qui enrichit l'expérience d'apprentissage. En étudiant aux côtés de pairs du monde entier, les étudiants acquièrent des perspectives précieuses sur différentes cultures, langues et perspectives. Cet environnement multiculturel favorise également des opportunités de réseautage global qui peuvent ouvrir des portes à des collaborations futures et à des perspectives de carrière." },
        { type: "title", value: "Beauté Naturelle et Aventures en Plein Air :" },
        { type: "num", value: "Au-delà de la salle de classe, la Chypre turque offre une beauté naturelle à couper le souffle et des aventures en plein air qui n'attendent qu'à être explorées. Des plages immaculées et des côtes escarpées aux montagnes verdoyantes et aux villages pittoresques, l'île est un paradis pour les amoureux de la nature et les passionnés de plein air. Après les cours, les étudiants peuvent se détendre en randonnant, en nageant ou simplement en profitant du paysage époustouflant." },
        { type: "title", value: "Hospitalité Chaleureuse et Environnement Sûr :" },
        { type: "num", value: "Les Chypriotes turcs sont réputés pour leur hospitalité chaleureuse et leur caractère amical, créant un environnement accueillant pour les étudiants internationaux. Avec son faible taux de criminalité et son atmosphère détendue, la Chypre turque est considérée comme l'une des destinations d'études les plus sûres de la région méditerranéenne. Les étudiants peuvent se concentrer sur leurs études et leur croissance personnelle en toute tranquillité d'esprit." },
        { type: "title", value: "Passerelle vers l'Échange Culturel et les Opportunités de Carrière :" },
        { type: "num", value: "Étudier en Chypre turque ouvre les portes à un monde d'échange culturel et d'opportunités de carrière. Que ce soit par le biais de stages, de volontariats ou d'événements culturels, les étudiants peuvent s'engager avec les communautés locales et acquérir des perspectives précieuses sur l'histoire, la politique et l'économie de la région. De plus, la Chypre turque sert de passerelle vers les pays voisins, offrant des opportunités de voyage, d'immersion linguistique et de développement professionnel." },
        { type: "title", value: "Histoire Riche et Patrimoine Culturel :" },
        { type: "num", value: "La Chypre turque possède une riche histoire remontant à des milliers d'années, avec des influences des anciennes civilisations telles que les Grecs, les Romains, les Byzantins et les Ottomans. Étudier ici offre une opportunité unique d'explorer des sites historiques, des trésors archéologiques et des monuments vieux de plusieurs siècles qui témoignent du passé légendaire de l'île. Des châteaux médiévaux et des ruines antiques aux charmantes vieilles villes et aux villages traditionnels, les étudiants peuvent se plonger dans le patrimoine culturel de l'île, développant ainsi une appréciation plus profonde pour son histoire et son identité diverses. En étudiant en Chypre turque, les étudiants enrichissent non seulement leur expérience académique, mais se lancent également dans un voyage à travers le temps, dévoilant les couches d'histoire qui ont façonné cette destination fascinante." },
        { type: "text", value: "En conclusion, la Chypre turque peut être un joyau caché dans le monde de l'éducation internationale, mais son attrait est indéniable. Avec son emplacement stratégique, ses universités réputées, ses coûts abordables, sa communauté multiculturelle et sa beauté naturelle, étudier ici promet une expérience transformante qui allie excellence académique, immersion culturelle et croissance personnelle. Alors, osez vous embarquer dans ce voyage unique - il pourrait vous conduire à découvrir un monde de possibilités que vous n'aviez jamais imaginées." },
        { type: "text2", value: "" },
        { type: "image", src: Nadia, alt: "Nadia Boukdir, CO-FONDATRICE DE DIRAVENIR", name: "Écrit par Nadia Boukdir", description: "CO-FONDATRICE DE DIRAVENIR" }
      ]
    }

  ]

  // State to hold the current article
  const [currentArticle, setCurrentArticle] = useState(null);

  useEffect(() => {
    // Find the article by path
    const article = articles.find(article => article.path === articlePath);

    // If no article found, navigate to the homepage
    if (!article) {
      navigate('/');
    } else {
      // Set the current article
      setCurrentArticle(article);
    }
  }, [articlePath, navigate]);

  if (!currentArticle) {
    return <div>Loading...</div>;
  }

  // Function to render the content
  const renderContent = (content) => {
    let numCount = 0;
    return content
      .filter((item, index) => index !== 0)
      .map((item, index) => {
        if (item.type === 'text') {
          return <p className='article_text' key={index}>{item.value}</p>;
        } else if (item.type === 'title') {
          return <h2 key={index}>{item.value}</h2>;
        } else if (item.type === 'num') {
          numCount += 1;
          return (
            <div key={index} className="article_num">
             
              <p>{item.value}</p>
            </div>
          );
        }else if (item.type === 'num1') {
          return (
            <div key={index} className="article_num1">
              <h3>{item.value}</h3>
              {/* Optionally include a paragraph for additional content if needed */}
            </div>
          );}
          else if (item.type === 'num2') {
            return (
              <div key={index} className="article_num2">
                <h3>{item.value}</h3>
                {/* Optionally include a paragraph for additional content if needed */}
              </div>
            );}
        else if (item.type === 'text2' || item.type === 'image') {
          return (
            <div className="inline-container">
              {item.type === 'text2' && (
                <div className="text2">
                  <p>{item.value}</p>
                </div>
              )}
              {item.type === 'image' && (
                <div className="action_box9">
                  <div
                    className="action_img9"
                    style={{ backgroundImage: `url('${item.src}')` }}
                  ></div>
                  <div className="action_info9">
                    <div className="action_name">{item.name}</div>
                    <div className="action_description">{item.description}</div>
                  </div>
                </div>
              )}
            </div>
          );
        }


      });
  };
  return (
    <>
      <div className="topbar">
        <div className="topbar_wrapper">
          <div className="left_topbar">
            <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24.005"
                viewBox="918 1559 24 24.005"
              >
                <g fillRule="evenodd" data-name="Group 34">
                  <path
                    d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
                    data-name="Path 19"
                  ></path>
                  <path
                    fill="#ffd355"
                    d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
                    data-name="Path 20"
                  ></path>
                </g>
              </svg>
              <span></span>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="1081 1559 24 24"
              >
                <g data-name="Group 37">
                  <path
                    fillRule="evenodd"
                    d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
                    data-name="Layer 2"
                  ></path>
                </g>
              </svg>
              <span></span>
            </a>
            <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
              </svg>
              <span></span>
            </a>
          </div>
          <div className="right_topbar">
            <div className="topbar_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#5f6368"
                viewBox="0 -960 960 960"
              >
                <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
              </svg>
              <span>+212 604-806362</span>
            </div>
            <div className="topbar_item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#5f6368"
                viewBox="0 -960 960 960"
              >
                <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
              </svg>
              <span>contact@diravenir.com</span>
            </div>
            <div className="topbar_item topbar_item_lang" onClick={() => console.log(lang)}>
              <LanguageIcon />
              <span onClick={() => setLang("en")} >English  / </span>
              <span onClick={() => setLang("fr")} >Français</span>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar">
        <div className="navbar_wrapper">
        <Link to="/" className="logo_container">
          <img src={Logo} />
        </Link>
          <div className="nav_links">
            <a href="#about">{lang == "en" ? "About Us" : "À propos"}</a>
            <a href="#services">{lang == "en" ? "Our Services" : "Nos services"}</a>
            <a href="/blog">Blog</a>
          </div>
          <Link to="/postuler" className="nav_btn">{lang == "en" ? "Apply" : "Postuler"}</Link>
        </div>
      </div>

      <div className="article">
        <div className="article_wrapper">
          <div className="article_header">
            <div className="article_img_container">
              <div
                className="article_img"
                style={{
                  backgroundImage: "url('" + (
                    currentArticle.englishTitle == "Studying Abroad: Why It Matters More Than Ever ?" ? Blog1 :
                      currentArticle.englishTitle == "8 Reasons for Moroccan to Study Abroad in China" ? Blog2 :
                        currentArticle.englishTitle == "8 Reasons to Study in Turkish Cyprus as a Moroccan Student" ? Blog3 :
                        currentArticle.englishTitle == "Experience World-Class Education in China" ? Blog4 :
                        currentArticle.englishTitle == "Discover Top Universities in Northern Cyprus" ? Blog5 :
                        currentArticle.englishTitle == "Learning Chinese as a student, is that possible?" ? Blog6 :
                        currentArticle.englishTitle == "test" ? Blog3 :
                        
                        ""
                  ) + "')"
                }}
              ></div>
              <div className="article_info">
                <h1>{lang == "en" ? currentArticle.englishTitle : currentArticle.frenchTitle}</h1>
                <p>{lang == "en" ? currentArticle.englishContent[0].value : currentArticle.frenchContent[0].value}</p>
              </div>
              <div className='article_info2'>
                {renderContent(lang == "en" ? currentArticle.englishContent : currentArticle.frenchContent)}
              </div>

            </div>



          </div>

        </div>





      </div>



      <div className="footer">
        <div className="footer_wrapper">
          <div className="footer_logo">
            <div className="logo_container">
            <img src={logo_purple2} />
            </div>
            <div className="footer_vert_info">
              
            <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span>diravenir</span>
      </a>
              <a target="_blank"  href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
                <svg className="social"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="1081 1559 24 24"
                  
                  
                >
                  <g data-name="Group 37">
                    <path
                      fill="#000000"
                      fillRule="evenodd"
                      d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
                      data-name="Layer 2"
                    ></path>
                  </g>
                </svg>
                <span>diravenir</span>
              </a>
              <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span>Diravenir</span>
      </a>



            </div>

          </div>

          <div className="footer_location">
            <h4>{lang == "en" ? "Our location" : "Notre emplacement"}</h4>
            <p>179, Angle des Boulevards de Londres et de la Résistance, Quartier, Av. Mers Sultan,<br />Casablanca20250</p>
          </div>

          <div className="footer_contact">
            <h4>{lang == "en" ? "Contact Us" : "Contactez nous"}</h4> 
            <div className="footer_vert_info">
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
                </svg>
               <p> <span>+212 604-806362</span></p> 
              </div>
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
                </svg>
                <p><span>contact@diravenir.com</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Article