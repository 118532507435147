import "./App.css";
import { Link, Route, Routes, Navigate } from "react-router-dom"; // Import Navigate for redirection
import Landing from "./components/Landing";
import Loading from "./components/Loading";
import { useState, useEffect } from "react";
import Article from "./components/Article";
import Postuler from "./components/Postuler";
import AllArticles from "./components/AllArticles";

function App() {
  const [lang, setLang] = useState("en");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a fetch or a heavy task
    setTimeout(() => {
      setIsLoading(false); // Update loading state after task completion
    }, 2000); // Delay of 2 seconds
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/postuler" element={<Postuler lang={lang} setLang={setLang} />} />
          <Route path="/article/:articlePath" element={<Article lang={lang} setLang={setLang} />} />
          <Route path="/AllArticles" element={<AllArticles />} />
          {/* Redirect /blog to /AllArticles */}
          <Route path="/blog" element={<Navigate to="/AllArticles" />} />
          <Route path="/blog" element={<Navigate to="/AllArticles" />} />
          <Route path="/" element={<Landing lang={lang} setLang={setLang} />} />
        </Routes>
      )}
    </>
  );
}

export default App;
