import Logo from "../media/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import logo_purple2 from '../media/images/logo_purple2.png';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; 
import nadiaTeam from "../media/images/nadiaTeam.png"
import houdaTeam from "../media/images/houdaTeam.png"

import meryemTeam from "../media/images/meryemTeam.png"
import Header1 from "../media/images/IMG.JPG"
import Header2 from "../media/images/IMG_8255.png"
import Header3 from "../media/images/DSC03331.jpg"
import Header4 from "../media/images/DSC00753.jpg"
import Header5 from "../media/images/IMG_767.jpg"
import miniature from "../media/images/miniature_pic.jpg"
import min1 from "../media/images/min1.jpeg"
import min2 from "../media/images/min2.jpg"
import min3 from "../media/images/min3.jpeg"
import min4 from "../media/images/min4.jpeg"

import Houda from "../media/images/houda.jpg"

import Blog1 from "../media/images/blog1.png"
import Blog2 from "../media/images/blog2.png"
import Blog3 from "../media/images/blog3.png"
 
import cover from "../media/images/cover.png"

import Partner1 from "../media/images/partner1.png"
import Partner2 from "../media/images/partner2.png"
import Partner3 from "../media/images/partner3.png"
import Partner4 from "../media/images/partner4.png"

import Nadia from "../media/images/nadia.jpg"
import Meryem from "../media/images/meryem.jpg"
import '@fortawesome/fontawesome-free/css/all.css';

import LanguageIcon from "../icons/language";


function Landing({lang, setLang}) {
  useEffect(() => {
   

   
}, []);

const [currentPerson, setCurrentPerson] = useState(0); // Index of the current person
  
  const people = [
    { name: "MARYEM", role: "CO FOUNDER", image: meryemTeam, color: "#8b1080" },
    { name: "MARYEM", role: "CO FOUNDER", image: houdaTeam, color: "#FBC91B" },
    { name: "MARYEM", role: "CO FOUNDER", image: nadiaTeam, color: "#8b1080" }
  ];
  
  const nextPerson = () => {
    setCurrentPerson((currentPerson + 1) % people.length); // Cycle through people
  };
  
  const prevPerson = () => {
    setCurrentPerson((currentPerson - 1 + people.length) % people.length); // Cycle through people
  };
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
    navigate('/AllArticles');
  };
  const navigate = useNavigate();
  return (
    
    <>
    
<div className="topbar">
  <div className="topbar_wrapper">
    <div className="left_topbar">
      <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="1081 1559 24 24"
        >
          <g data-name="Group 37">
            <path
              fillRule="evenodd"
              d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
              data-name="Layer 2"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span></span>
      </a>
    </div>
    <div className="right_topbar">
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
        </svg>
        <span>+212 604-806362</span>
      </div>
      <div className="topbar_item topbar_item_lang">
        <LanguageIcon />
        <span onClick={() => setLang("en")} >English /</span>
        <span onClick={() => setLang("fr")} >Français</span>
      </div>
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
        </svg>
        <span>contact@diravenir.com</span>
      </div>
    </div>
  </div>
</div>



      <div className="navbar">
        <div className="navbar_wrapper">
          <div className="logo_container">
            <img src={Logo} />
          </div>
          <div className="nav_links">
            <a href="#about">{lang == "en" ? "About Us" : "À propos"}</a>
            <a href="#services">{lang == "en" ? "Our Services" : "Nos services"}</a>
            <a href="#blog">Blog</a>
          </div>
          <Link to="/postuler" className="nav_btn">{lang == "en" ? "Apply" : "Postuler"}</Link>
        </div>
      </div>

      <div className="header">
        <div className="header_content">
        <span className="header_t_bold handwriting" style={{ fontSize: "2em" }}>
  {lang == "en" ? "We" : "Nous"}
</span>

          <span className="header_t_bold"><span>D</span>{lang == "en" ? "IRECT" : "IRIGEONS"}</span>
          <span className="header_t_bold"><span>I</span>{lang == "en" ? "MPACT" : "MPACTONS"}</span>
          <span className="header_t_bold"><span>R</span>{lang == "en" ? "ESHAPE" : "EMODELONS"}</span>
          <span className="header_t_bold handwriting" style={{ fontSize: "2em" }}>{lang == "en" ? "Students with" : "nos Étudiants avec"}</span>
          <div className="header_flex">
            <span>{lang == "en" ? "One to one orientation" : " Orientation individuelle"}</span>
            <span>{lang == "en" ? "Academic scholarships" : "Bourses d'études"}</span>
            <span>{lang == "en" ? "Opportunities Abroad" :"Opportunités à l'étranger"}</span>
          </div>
          <Link to="/postuler" className="header_btn">
            <span className="header_btn" >{lang == "en" ? "Apply" : "Postuler"}</span>
          </Link>
        </div>
        <div className="header_media">
        <div className="header_img" style={{ backgroundImage: "url('" + Header1 + "')" }}></div>

          
          <div className="header_img" style={{ backgroundImage: "url('" + Header2 + "')" }}></div>
          <div className="header_img" style={{ backgroundImage: "url('" + Header3 + "')" }}></div>
          <div className="header_img" style={{ backgroundImage: "url('" + Header4 + "')" }}></div>
          <div className="header_img" style={{ backgroundImage: "url('" + Header5 + "')" }}></div>
        </div>
      </div>
      <div className="action_box3">
          <div className="action5">
          <div className="about" id="about">
          <div className="about_video_container">
          <iframe className="video_iframe" src="https://player.vimeo.com/video/952950738?h=e7eda8d952" width="640" height="1138" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
</div>
          <div className="about_info">
          <div className="about_title">
            <span>{lang == "en" ? "Who" : "Qui"}</span>
            <span>{lang == "en" ? "are " : "sommes-"}</span>
            <span>{lang == "en" ? "we" : "nous"}</span>
            <span>?</span>
          </div>
  
          <div className="about_info_wrapper"  >

            <div className="about_item">
              <div className="about_img" style={{ backgroundImage: "url('" + Nadia + "')" }}></div>
              <span className="about_name">NADIA BOUKDIR </span>
              <ul className="about_list">
                <li className="about_list_item">{lang == "en" ? "Master's degree in Marketing and Commercial Actions" : "Master en marketing et actions commerciales de l' " } <span> ENCG SETTAT</span></li>
                <li className="about_list_item">{lang == "en" ? "Alumni of the UK government's International programme" : "Lauréate du programme international du gouvernement britannique"} <span>CHEVENING</span></li>
                <li className="about_list_item">{lang == "en" ? "MSc degree in Management of Tourism Marketing," : "Master de science en gestion du marketing touristique de"} <span>{lang == "en" ? "Strathclyde University, United Kingdom " : "l'université Strathclyde, au Royaume-Uni"}</span></li>
              </ul>
            </div>
            <div className="about_item">
              <div className="about_img" style={{ backgroundImage: "url('" + Meryem + "')" }}></div>
              <span className="about_name">MERYEM DERNI</span>
              <ul className="about_list">
                <li className="about_list_item">{lang == "en" ? "Master's degree in Marketing and Commercial Actions" : "Master en marketing et actions commerciales de l'" } <span>ENCG SETTAT</span></li>
                <li className="about_list_item">{lang == "en" ? "Diploma in Finance and Accounting from Jean-Monnet University" : "Licence professionnelle en comptabilité et en Finance de l'Université Jean-Monnet en "} <span> France</span></li>
                <li className="about_list_item">{lang == "en" ? "MSc degree in International Business," : "Master de science en commerce et marketing international de "} <span>  Kedge business School, France</span></li>
              </ul>
             
            </div>
          </div>

        </div>
         <div className="about_background"></div>
      </div>
          </div>
</div>
       
        

      <div className="services" id="services">
        <div className="services_title">

          <span>{lang == "en" ? "What" : "Ce que"}</span>
          <span>{lang == "en" ? "We" : "nous"}</span>
          <span>{lang == "en" ? "Can do for" : " pouvons faire pour "}</span>
          <span>{lang == "en" ? "You" : "vous"}</span>
          <span>!</span>
        </div>
        <div className="services_list">
          <div className="services_item">
            <div className="service_title"><i className="fas fa-user-graduate "></i> {lang == "en" ? "Orientation académique" : "Orientation académique"}</div>
            <div className="service_description">{lang == "en" ? "We guide students in choosing the study program and country that best match their academic and professional aspirations, providing them with personalized support." : 
            "Nous guidons les étudiants dans le choix du programme d'études et du pays qui correspondent le mieux à leurs aspirations académiques et professionnelles, en leur fournissant un soutien personnalisé."}</div>
          </div>
          <div className="services_item">
            <div className="service_title"><i className="fas fa-award"></i> {lang == "en" ? "Scholarships" : "Bourses d'études"}</div>
            <div className="service_description">
              {lang == "en" ? "We assist students in obtaining scholarships in China and Cyprus by offering them a comprehensive set of dedicated services, ranging from administrative assistance to on-site student reception" : 
              "Nous aidons les étudiants à obtenir des bourses d'études en Chine et en Chypre en leur offrant un ensemble complet de services dédiés, allant de l'assistance administrative à l'accueil des étudiants sur place."}
              </div>
          </div>
          <div className="services_item">
            <div className="service_title"><i className="fas fa-globe-americas"></i>{lang == "en" ? " Opportunities Abroad" : " Opportunités à l'étranger"}</div>
            <div className="service_description">
              {lang == "en" ? "We offer various opportunities in foreign countries. These opportunities might include pursuing international internships, participating in exchange programs, volunteering in foreign communities, or exploring new cultures and languages." :
              "Nous offrons diverses possibilités dans les pays étrangers. Il peut s'agir d'effectuer des stages internationaux, de participer à des programmes d'échange, de faire du bénévolat dans des communautés étrangères ou d'explorer de nouvelles cultures et de nouvelles langues."}
              </div>
          </div>
        </div>
      </div>
      <div className="action" >
    <div className="action_box">
        <div className="action_img" style={{ backgroundImage: "url('" + Houda + "')" }}></div>
        <div className="action_info">
            <div className="action_name">Houda Badre</div>
            <div className="action_description">{lang == "en" ? "Educational Consultant" : "Consultante en éducation"}</div>
        </div>
        <div className="action_content">
            <span>{lang == "en" ? "Need advice for your academic journey?" : "Besoin de conseils pour votre parcours académique ?"}</span>
            <span>{lang == "en" ? "We offer a" : "Nous proposons une"} <span>{lang == "en" ? "free consultation" : "consultation gratuite"}</span>!</span>
        </div>
        <Link to="/postuler" className="action_btn">{lang == "en" ? "Start Your Journey" : "Commencez votre voyage"}</Link>
    </div>
</div>


      <div className="steps">
      <div className="steps_title">
  
    <span>{lang == "en" ? "Your" : "Votre"}</span>
    <span>{lang == "en" ? "journey" : "voyage"}</span>
    <span>{lang == "en" ? "starts" : "commence"}</span>
    <span>{lang == "en" ? "Here" : "ici"}</span>
    <span>!</span>
 
</div>

        <div className="steps_container">
          <div className="step_item">
            <div className="step_number">
              <span>1</span>
              <div className="step_bar"></div>
            </div>
            <div className="step_title">Consultation</div>
            <div className="step_description"> {lang == "en" ? "Students meet our team to discuss their educational aspirations, career goals, and concerns. Together, they develop a tailored plan." : 
            "Les étudiants rencontrent notre équipe pour discuter de leurs aspirations en matière d'éducation, de leurs objectifs de carrière et de leurs préoccupations. Ensemble, nous élaborons un plan sur mesure."}    </div>
          </div>
          <div className="step_item">
            <div className="step_number">
              <span>2</span>
              <div className="step_bar"></div>
            </div>
            <div className="step_title">Orientation</div>
            <div className="step_description">{lang == "en" ? "            After the consultation, students attend two orientation sessions. We use international tests and assessments to customize academic projects to their interests and market demands." : 
            "Après la consultation, les étudiants participent à deux séances d'orientation. Nous utilisons des tests et des évaluations internationaux pour adapter les projets académiques à leurs intérêts et aux exigences du marché."}</div>
          </div>
          <div className="step_item">
            <div className="step_number">
              <span>3</span>
              <div className="step_bar"></div>
            </div>
            <div className="step_title">Admission</div>
            <div className="step_description">{lang == "en" ? "            Upon deciding to enroll, we initiate the admission process. We handle the application submission to our partner universities and secure final admission for the student." : 
            "Lorsque l'étudiant décide de s'inscrire, nous entamons le processus d'admission. Nous nous occupons de la soumission des candidatures à nos universités partenaires et nous assurons l'admission définitive de l'étudiant."}</div>
          </div>
          <div className="step_item">
            <div className="step_number">
              <span>4</span>
              <div className="step_bar"></div>
            </div>
            <div className="step_title">{lang == "en" ? "Travel" : "Voyage"}</div>
            <div className="step_description">{lang == "en" ? "              We assist students with visa processing and book their flight tickets to the destination. Additionally, we provide reception services upon their arrival." :
            "Nous aidons les étudiants à obtenir leur visa et à réserver leur billet d'avion pour leur destination. En outre, nous fournissons des services d'accueil à leur arrivée"}</div>
          </div>
        </div>
        <Link to="/postuler" className="step_btn">{lang == "en" ? "Start Your Journey" : "Commencez votre voyage"}</Link>
      </div>
      <div className="action2">
      <div className="action_box2">
      <div className="partners">
        <div className="partners_title"><span>{lang == "en" ? "Our" : "Nos"}</span><span>{lang == "en" ? "Partners" : "partenaires"}</span></div>
        <div className="partners_container">
          <img src={Partner1} />
          <img src={Partner2} />
          <img src={Partner3} />
          <img src={Partner4} />
        </div>
      </div>
      </div>
      </div>
      <div className="blog" id="blog">
        <div className="blog_title">
          <span>{lang == "en" ? "Academic" : "la vie"}</span>
          <span>{lang == "en" ? "Life" : "académique"}</span>
          <span>{lang == "en" ? "Beyond" : "Au-delà"}</span>
          <span>{lang == "en" ? "Borders" : "des Frontières"}</span>
        </div>
        <div className="blog_container">
  <Link to="/article/studying-abroad-why-it-matters-more-than-ever" className="article_item">
    <div className="article_img1" style={{ backgroundImage: `url(${Blog1})` }}></div>
    <div className="article_title">{lang === "en" ? "Studying Abroad: Why It Matters More Than Ever ?" : "Étudier à l'étranger : Pourquoi cela compte plus que jamais ?"}</div>
    <div className="article_read">{lang === "en" ? "Studying abroad is more than just a trend or a checkbox on a resume; it's a transformative experience that can shape the trajectory..." : "Étudier à l'étranger n'est pas seulement une tendance ou une case à cocher sur un CV ; c'est une expérience transformatrice qui peut façonner la trajectoire..."}</div>
    <div className="article_action">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/8-reasons-to-study-in-turkish-cyprus-as-a-moroccan-student" className="article_item">
    <div className="article_img1" style={{ backgroundImage: `url(${Blog3})` }}></div>
    <div className="article_title">{lang === "en" ? "8 Reasons to Study in Turkish Cyprus as a Moroccan Student" : "8 Raisons d'Étudier à Chypre Turque en tant qu'Étudiant Marocain"}</div>
    <div className="article_read">{lang === "en" ? "In the realm of international education, there are destinations that shine brightly on the global stage, drawing students from  and... " : "Dans le domaine de l'éducation internationale, il existe des destinations qui brillent sur la scène mondiale, attirant des étudiants de loin. Pourtant, parmi... "}</div>
    <div className="article_action">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <Link to="/article/8-reasons-for-Moroccan-to-study-abroad-in-china" className="article_item">
    <div className="article_img1" style={{ backgroundImage: `url(${Blog2})` }}></div>
    <div className="article_title">{lang === "en" ? "8 Reasons for Moroccans to Study Abroad in China" : "8 raisons pour les Marocains d'étudier à l'étranger en Chine"}</div>
    <div className="article_read">{lang === "en" ? "For Moroccan students with dreams as vast as the Sahara, the prospect of studying abroad is like embarking on a journey across uncharted territories..." : "Pour les étudiants marocains dont les rêves sont aussi vastes que le Sahara, la perspective d'étudier à l'étranger s'apparente à un voyage à travers des territoires inexplorés..."}</div>
    <div className="article_action">
      <span>{lang === "en" ? "Go to article" : "Consulter l'article"}</span>
      <svg className="social" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="M504-480L320-664l56-56 240 240-240 240-56-56 184-184z"></path>
      </svg>
    </div>
  </Link>
  <button onClick={handleShowMore} className="show_more_button">
        {showMore ? (lang === "en" ? "Show Less" : "Montrer moins") : (lang === "en" ? "Show More" : "Montrer plus")}
      </button>
  
  </div>
 
      </div>
    
    <br></br>
      <div className="footer">
        <div className="footer_wrapper">
          <div className="footer_logo">
            <div className="logo_container">
            <img src={logo_purple2} />
            </div>
            <div className="footer_vert_info">
              
            <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span>diravenir</span>
      </a>
              <a target="_blank"  href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
                <svg className="social"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="1081 1559 24 24"
                  
                  
                >
                  <g data-name="Group 37">
                    <path
                      fill="#000000"
                      fillRule="evenodd"
                      d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
                      data-name="Layer 2"
                    ></path>
                  </g>
                </svg>
                <span>diravenir</span>
              </a>
              <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span>Diravenir</span>
      </a>



            </div>

          </div>

          <div className="footer_location">
            <h4>{lang == "en" ? "Our location" : "Notre emplacement"}</h4>
            <p>179, Angle des Boulevards de Londres et de la Résistance, Quartier, Av. Mers Sultan,<br />Casablanca20250</p>
          </div>

          <div className="footer_contact">
            <h4>{lang == "en" ? "Contact Us" : "Contactez nous"}</h4> 
            <div className="footer_vert_info">
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
                </svg>
               <p> <span>+212 604-806362</span></p> 
              </div>
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
                </svg>
                <p><span>contact@diravenir.com</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
const buttonStyle = {
  backgroundColor: '#8b1080',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  margin: '0 10px',
};
export default Landing;
