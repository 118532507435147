import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logo from "../media/images/logo.png";
import logo_purple2 from '../media/images/logo_purple2.png';

import Header3 from "../media/images/header3.jpg"
import RadioIcon from '../icons/radio';
import RadioActiveIcon from '../icons/radio-active';
import Swal from 'sweetalert2';

import emailjs from '@emailjs/browser';
import LanguageIcon from '../icons/language';
                <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>

function Postuler({lang, setLang}) {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [academicLevel, setAcademicLevel] = useState('');

    const [selectedService, setSelectedService] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleCityChange = (e) => setCity(e.target.value);
    const handleAcademicLevelChange = (e) => setAcademicLevel(e.target.value);

    const handleServiceSelection = (service) => setSelectedService(service);
    const [showPopup, setShowPopup] = useState(false);

const togglePopup = () => {
    setShowPopup(!showPopup);
};
    const handleSubmit = () => {
      if (!firstName || !lastName || !email || !phoneNumber || !city || !academicLevel || !selectedService) {
        Swal.fire({
            icon: 'warning',
            title: 'Please fill in all fields',
            showConfirmButton: false,
            timer: 1500
        });
        return;
    }
        emailjs.send('service_2z6dg08', 'template_95grosy', {
            fullName: firstName + " " + lastName,
            email,
            whatsapp: phoneNumber,
            city,
            academicLevel,
            service: selectedService,
        }, {
            publicKey: 'oyBMWhan9dBZtlH93',
        }).then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Message submitted successfully!",
                  showConfirmButton: false,
                  timer: 3000,
                  backdrop: `
                    rgba(251, 201, 27,0.4)
                    url("/path/to/image.jpg")
                    left top
                    no-repeat
                  `,
                  customClass: {
                    popup: 'popup-class',
                    header: 'header-class',
                    title: 'title-class',
                    closeButton: 'close-button-class',
                    icon: 'icon-class',
                    image: 'image-class',
                    content: 'content-class',
                    input: 'input-class',
                    actions: 'actions-class',
                    confirmButton: 'confirm-button-class',
                    denyButton: 'deny-button-class',
                    cancelButton: 'cancel-button-class',
                    footer: 'footer-class'
                  }
                });
                
                

              setSuccessMessage('Application submitted successfully!');
                togglePopup();
                // Optionally, clear the form fields here
            },
            (error) => {
              console.error('could not be sent:', error);
            },
        );
    };


    return (
        <>
            
<div className="topbar">
  <div className="topbar_wrapper">
    <div className="left_topbar">
      <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="1081 1559 24 24"
        >
          <g data-name="Group 37">
            <path
              fillRule="evenodd"
              d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
              data-name="Layer 2"
            ></path>
          </g>
        </svg>
        <span></span>
      </a>
      <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span></span>
      </a>
    </div>
    <div className="right_topbar">
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
        </svg>
        <span>+212 604-806362</span>
      </div>
      <div className="topbar_item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#5f6368"
          viewBox="0 -960 960 960"
        >
          <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
        </svg>
        <span>contact@diravenir.com</span>
      </div>
      <div className="topbar_item topbar_item_lang">
        <LanguageIcon />
        <span onClick={() => setLang("en")} >English  / </span>
        <span onClick={() => setLang("fr")} >Français</span>
      </div>
    </div>
  </div>
</div>



<div className="navbar">
        <div className="navbar_wrapper">
        <Link to="/" className="logo_container">
           <img src={Logo} />
        </Link>
          <div className="nav_links">
            <a href="#about">{lang == "en" ? "About Us" : "À propos"}</a>
            <a href="#services">{lang == "en" ? "Our Services" : "Nos services"}</a>
            <a href="/blog">Blog</a>
          </div>
          <Link to="/postuler" className="nav_btn">{lang == "en" ? "Apply" : "Postuler"}</Link>
        </div>
      </div>


            <div className="postuler">
                <div className="postuler_title postuler_title_mobile"> <span>{lang == "en" ? "Let's Make it Happen Together" : "Réalisons-le ensemble"}</span></div>
                <div className="postuler_img_container">
                <div className="postuler_img" style={{ backgroundImage: "url('" + Header3 + "')" }}>
  <img src={Header3} alt="Background" style={{ display: 'none' }} loading="lazy" />
</div>
                    <div className='postuler_text '>
                        <p>{lang == "en" ? "After completing the form, expect a response from our team within 24 hours." : "Après avoir rempli le formulaire, attendez une réponse de notre équipe dans les 24 heures."}</p>
                        <p>{lang == "en" ? "We'll guide you through the process of realizing your dream of studying abroad at your desired university and destination." : "Nous vous guiderons dans le processus pour réaliser votre rêve d'étudier à l'étranger dans l'université et la destination de votre choix."}</p>
                    </div>
                </div>
                
                <div className="postuler_form">
                <div className="postuler_title"><span>{lang == "en" ? "Let's Make it Happen Together" : "Réalisons-le ensemble"} </span>
    <i className="fas fa-graduation-cap" style={{marginLeft: '10px'}}></i>
</div>

                    <div className="postuler_flex">
                        <div className="postuler_input">
                            <label>{lang == "en" ? "First name" : "Prénom"}</label>
                            <input type="text" value={firstName} onChange={handleFirstNameChange} />
                        </div>
                        <div className="postuler_input">
                            <label>{lang == "en" ? "Last name" : "Nom de famille"}</label>
                            <input type="text" value={lastName} onChange={handleLastNameChange} />
                        </div>
                    </div>
                    <div className="postuler_flex">
                    <div className="postuler_input">
                        <label>{lang == "en" ? "Email address" : "Adresse e-mail"}</label>
                        <input type="text" value={email} onChange={handleEmailChange} />
                    </div>
                    <div className="postuler_input">
                        <label>{lang == "en" ? "WhatsApp number" : "Numéro WhatsApp"}</label>
                        <input type="text" value={phoneNumber} onChange={handlePhoneNumberChange} />
                    </div>
                    </div>
                    <div className="postuler_flex">
                    <div className="postuler_input">
                        <label>{lang == "en" ? "City" : "Ville"}</label>
                        <input type="text" value={city} onChange={handleCityChange} />
                    </div>
                    <div className="postuler_input">
                        <label>{lang == "en" ? "Current academic level" : "Niveau académique actuel"}</label>
                        <input type="text" value={academicLevel} onChange={handleAcademicLevelChange} />
                    </div>
                    </div>
                    <div className="postuler_input">
                    <label>{lang == "en" ? "Service" : "Service"}</label>
                    <div className="postuler_options">
                        <div className={"postuler_option" + (selectedService == "china" ? " active" : "")} onClick={() => handleServiceSelection('china')}>
                            { selectedService == "china" ? <RadioActiveIcon /> : <RadioIcon /> }
                            <span>{lang == "en" ? "I'm interested in obtaining a scholarship in China." : "Je suis intéressé par l'obtention d'une bourse en Chine."}</span>
                        </div>
                        <div className={"postuler_option" + (selectedService == "cyprus" ? " active" : "")} onClick={() => handleServiceSelection('cyprus')}>
                            { selectedService == "cyprus" ? <RadioActiveIcon /> : <RadioIcon /> }
                            <span>{lang == "en" ? "I'm seeking a scholarship opportunity in Cyprus." : "Je recherche une opportunité de bourse en Chypre."}</span>
                        </div>
                        <div className={"postuler_option" + (selectedService == "orientation" ? " active" : "")} onClick={() => handleServiceSelection('orientation')}>
                            { selectedService == "orientation" ? <RadioActiveIcon /> : <RadioIcon /> }
                            <span>{lang == "en" ? "I need an orientation session." : "J'ai besoin d'une séance d'orientation."}</span>
                        </div>
                    </div>
                    </div>
                    <div className="postuler_submit" onClick={handleSubmit}
                    
                    >{lang == "en" ? "Apply" :  "Postuler"}</div>
                </div>
                </div>


                <div className="footer">
        <div className="footer_wrapper">
          <div className="footer_logo">
            <div className="logo_container">
            <img src={logo_purple2} />
            </div>
            <div className="footer_vert_info">
              
            <a target="_blank" href="https://www.instagram.com/diravenir?igsh=YndyOTdpd2Zkb292&utm_source=qr" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24.005"
          viewBox="918 1559 24 24.005"
        >
          <g fillRule="evenodd" data-name="Group 34">
            <path
              d="M919.565 1560.667c-1.88 1.953-1.495 4.028-1.495 10.332 0 5.235-.914 10.483 3.866 11.718 1.493.384 14.72.384 16.21-.002 1.99-.514 3.61-2.128 3.83-4.943.032-.393.032-13.147 0-13.548-.236-2.998-2.081-4.726-4.513-5.076-.558-.08-.67-.105-3.53-.11-10.143.005-12.366-.446-14.368 1.629z"
              data-name="Path 19"
            ></path>
            <path
              fill="#ffd355"
              d="M930.033 1562.177c-3.617 0-7.051-.321-8.363 3.045-.542 1.39-.463 3.197-.463 5.783 0 2.269-.073 4.402.463 5.781 1.309 3.369 4.771 3.046 8.361 3.046 3.464 0 7.035.36 8.363-3.046.542-1.404.463-3.183.463-5.781 0-3.449.19-5.675-1.482-7.346-1.694-1.694-3.984-1.482-7.346-1.482zm-.79 1.591c7.544-.012 8.504-.85 7.974 10.8-.188 4.122-3.326 3.67-7.183 3.67-7.032 0-7.234-.202-7.234-7.237 0-7.117.557-7.229 6.442-7.235zm5.502 1.465a1.059 1.059 0 100 2.118 1.059 1.059 0 000-2.118zm-4.712 1.238a4.533 4.533 0 100 9.066 4.533 4.533 0 000-9.066zm0 1.591c3.89 0 3.895 5.885 0 5.885-3.889 0-3.895-5.885 0-5.885z"
              data-name="Path 20"
            ></path>
          </g>
        </svg>
        <span>diravenir</span>
      </a>
              <a target="_blank"  href="https://www.linkedin.com/company/diravenir/" className="topbar_item">
                <svg className="social"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="1081 1559 24 24"
                  
                  
                >
                  <g data-name="Group 37">
                    <path
                      fill="#000000"
                      fillRule="evenodd"
                      d="M1101.727 1559h-17.454a3.273 3.273 0 00-3.273 3.273v17.454a3.273 3.273 0 003.273 3.273h17.454a3.273 3.273 0 003.273-3.273v-17.454a3.273 3.273 0 00-3.273-3.273zm-12.545 18.993a.506.506 0 01-.506.507h-2.156a.506.506 0 01-.507-.507v-9.038c0-.28.227-.508.507-.508h2.156c.28.001.506.228.506.508v9.038zm-1.585-10.402a2.045 2.045 0 110-4.091 2.045 2.045 0 010 4.09zm12.854 10.437a.465.465 0 01-.466.467h-2.319a.465.465 0 01-.466-.467v-4.234c0-.633.185-2.77-1.654-2.77-1.425 0-1.716 1.462-1.773 2.12v4.89a.466.466 0 01-.46.466h-2.238a.465.465 0 01-.465-.466v-9.119a.465.465 0 01.465-.466h2.239c.257 0 .466.208.466.466v.788c.53-.795 1.313-1.406 2.986-1.406 3.707 0 3.682 3.461 3.682 5.362l.003 4.37z"
                      data-name="Layer 2"
                    ></path>
                  </g>
                </svg>
                <span>diravenir</span>
              </a>
              <a target="_blank" href="https://web.facebook.com/profile.php?id=61553731883991&locale=fr_FR" className="topbar_item">
        <svg className="social"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M22.675 0h-21.35C.597 0 0 .597 0 1.325v21.351C0 23.404.597 24 1.325 24H12.82V14.709h-3.18V11.24h3.18V8.412c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24H17.4c-1.386 0-1.654.66-1.654 1.623v2.13h3.307l-.432 3.468h-2.875V24h5.632C23.403 24 24 23.404 24 22.675V1.325C24 .597 23.403 0 22.675 0z" />
        </svg>
        <span>Diravenir</span>
      </a>



            </div>

          </div>

          <div className="footer_location">
            <h4>{lang == "en" ? "Our location" : "Notre emplacement"}</h4>
            <p>179, Angle des Boulevards de Londres et de la Résistance, Quartier, Av. Mers Sultan,<br />Casablanca20250</p>
          </div>

          <div className="footer_contact">
            <h4>{lang == "en" ? "Contact Us" : "Contactez nous"}</h4> 
            <div className="footer_vert_info">
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12z"></path>
                </svg>
               <p> <span>+212 604-806362</span></p> 
              </div>
              <div className="topbar_item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5f6368"
                  viewBox="0 -960 960 960"
                >
                  <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160zm320-287q5 0 10.5-1.5T501-453l283-177q8-5 12-12.5t4-16.5q0-20-17-30t-35 1L480-520 212-688q-18-11-35-.5T160-659q0 10 4 17.5t12 11.5l283 177q5 3 10.5 4.5T480-447z"></path>
                </svg>
                <p><span>contact@diravenir.com</span></p>
              </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Postuler